export default {
  // encrypt api infos
  urls: [
    '/system/college/listbyparams', // 分页查询学校数据
    '/system/college/queryById', // 根据collegeNumber获取院校详情  【院校分数计划为get请求，所以只在返回加密即可】
    '/system/dominantmajor/queryByparam', // 根据collegeNumber获取院校优势专业
    '/system/enrollmentguide/queryByparam', // 根据collegeNumber获取院校招生章程列表
    '/sendSmsCode', // 获取短信验证码【非登录】
    '/sendWxBindSmsCode',
    '/login', // 手机号密码登录
    '/system/volunteer/intelligent/queryByIntelligentVolunteerOptimize', // 智能填报获取院校列表
    '/system/userDetails/edit', // 修改用户详细信息【个人中心使用】
    '/system/userDetails/add',
    '/system/user/updateDetails', // 编辑用户详细信息【仅在登录时使用】
    '/system/user/resetPwdUser', // 重置密码
    '/system/activationCode/activateVIP', // 激活码激活vip
    '/system/alipay/tradeQuery',   //支付宝支付状态轮询
    '/system/volunteerResult/getVolunteerResult',  //自主填报报志愿接口
    '/system/volunteer/intelligentResult/getIntelligentResult', //智能报志愿接口
    '/system/course/list',     //查询课程列表
    '/system/alipay/trade/notify',

  ]
}
