<template>
  <div style="margin-top: 30px;">
    <!-- 搜索条件头部 -->
    <div class="searchTitleBox" :class="{ searchTitleBoxActive: ZZORZN == 'ZN' }">
      <div @click="type = 1" class="searchTitleItem indexFz" :class="{ activeSearchTitle: type == 1 }">
        院校
      </div>
      <div @click="type = 2" class="searchTitleItem indexFz" :class="{ activeSearchTitle: type == 2 }">
        专业
      </div>
      <div @click="type = 3" class="searchTitleItem indexFz" :class="{ activeSearchTitle: type == 3 }">
        地区
      </div>
      <div @click="type = 4" class="searchTitleItem indexFz" :class="{ activeSearchTitle: type == 4 }">
        高级
      </div>
    </div>
    <div>
      <!-- 【院校】【院校】【院校】 -->
      <div v-show="type == 1">
        <!-- 【院校--院校性质】 -->
        <div class="condition">
          <div class="searchTitle">院校性质(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'yxxz')" v-for="(item, index) in yxxzList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【院校--院校层次】 -->
        <div class="condition">
          <div class="searchTitle">院校层次(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'yxcc')" v-for="(item, index) in education" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【院校--院校特色】 -->
        <div class="condition">
          <div class="searchTitle">院校特色(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'yxts')" v-for="(item, index) in educational" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【院校--院校类型】 -->
        <div class="condition">
          <div class="searchTitle">院校类型(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'yxlx')" v-for="(item, index) in collegeGenreList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 【专业】【专业】【专业】 -->
      <div v-show="type == 2">
        <!-- 【专业--专业名称】 -->
        <div class="condition">
          <div class="searchTitle">自选专业(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <el-cascader collapse-tags :disabled="gnbunberDisable" clearable v-model="gbnumbers" :props="customProps"
              :options="levearr" :key="majorCasckey" @change="gbNumberChange" />
            <span v-show="ZZORZN == 'ZZ' && onlyZKShow" class="infoToast">(专业、就业二选一)</span>
            <span v-show="ZZORZN == 'ZN'" class="infoToast">
              (专业、推荐、 <label v-if="onlyZKShow">就业三选一</label>  <label v-else>二选一</label> )
            </span>
          </div>
        </div>
        <!-- 【专业--专家推荐】 -->
        <div class="condition" v-show="ZZORZN == 'ZN'">
          <div class="searchTitle">专家推荐</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <el-select v-model="recommendSex" :disabled="fillmothodDisable" clearable @change="fillmothodChange"
              placeholder="请选择专家推荐专业">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
        </div>
        <!-- 【专业--就业方向】 -->
        <div class="condition" v-show="onlyZKShow">
          <div class="searchTitle">就业方向(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'jyfx')" st v-for="(item, index) in jyfxList" :key="index"
              :class="{ active: item.show }"
              :style="{ cursor: (recommendSex || gbnumbers.length != 0 ? 'not-allowed' : 'pointer') }">{{ item.name
              }}</span>
          </div>

        </div>
      </div>
      <!-- 【地区】【地区】【地区】 -->
      <div v-show="type == 3">
        <!-- 【地区--地区名称】 -->
        <div class="condition">
          <div class="searchTitle">城市名称(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <el-cascader placeholder="默认全选（可选择）" collapse-tags clearable v-model="areaNameType" :props="customPropsArea"
              :options="provienceAndcityArea" />
          </div>
        </div>
        <!-- 【地区--城市等级】 -->
        <div class="condition">
          <div class="searchTitle">城市等级(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'csdj')" v-for="(item, index) in csdjList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【地区--地理区域】 -->
        <div class="condition">
          <div class="searchTitle">地理区域(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'dlqy')" v-for="(item, index) in dlqyList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 【高级】【高级】【高级】 -->
      <div v-show="type == 4" style="position:relative">
        <!-- 【高级--招生类型】 -->
        <div class="condition">
          <div class="searchTitle">招生类型(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <el-tooltip class="item" effect="dark" v-for="(item, index) in zslxList" content="提示：报考地方专项，考生需具有连续3年农村户籍且在当地县高中具有连续3年学籍并实际就读" :disabled="item.name != '地方专项计划'"  :key="index"
           placement="top">
            <span   @click="changeActive(index, 'zslx')"
              :class="{ active: item.show }">{{ item.name }}</span>
          </el-tooltip>
          </div>
          <!-- 招生类型提示性文字 -->
          <!-- <p class="slxtext" :style="{ width: (ZZORZN == 'ZZ' ? '520px' : '285px') }">
            提示：报考国家专项，考生需具有政策规定贫困县所在地连续3年户籍且在当地县及以下高中具有连续3年学籍并实际就读；报考地方专项，考生需具有连续3年农村户籍且在当地县高中具有连续3年学籍并实际就读
          </p> -->
        </div>
        <!-- 【高级--学科评估】 -->
        <div class="condition" v-show="onlyZKShow">
          <div class="searchTitle">学科评估(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'xkpg')" v-for="(item, index) in xkpgList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【高级--学费标准】  v-show="ZZORZN == 'ZN'" -->
        <div class="condition">
          <div class="searchTitle">学费标准(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'xfbz')" v-for="(item, index) in xfbzList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【高级--硕博点】 v-show="ZZORZN == 'ZN'"   v-show="onlyZKShow" 专科批不显示 -->
        <div class="condition" v-show="onlyZKShow">
          <div class="searchTitle">硕博点(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'sbd')" v-for="(item, index) in sbdList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
        <!-- 【高级--名企招聘】  v-show="ZZORZN == 'ZN'"  v-show="onlyZKShow" 专科批不显示 -->
        <div class="condition" >
          <div class="searchTitle">名企招聘(可多选)</div>
          <div :class="ZZORZN == 'ZZ' ? 'searchTitleZZ' : 'searchTitleZN'">
            <span @click="changeActive(index, 'mqzp')" v-for="(item, index) in mqzpList" :key="index"
              :class="{ active: item.show }">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div v-show="ZZORZN == 'ZN'" style="display: flex;align-items: center;padding-left: 33%;">
      <div class="submitCss" @click="infoOkChange('submit')">
        完成
      </div>
      <div class="submitCss" style="background: #eb9b09;width: 13%;" @click="resetForm">
        重置
      </div>
    </div>
    <el-dialog title="" :visible.sync="znVipDialogShowFlag" custom-class="vipToastDialog" width="35%"
      :before-close="handleClose">
      <div v-if="userType == '05'">
        <div class="textVip">
          <span>智能填报专属功能，请到智能填报点击使用</span>
          <div class="btn" style="width: 70%;" @click="znVipDialogShowFlag = !znVipDialogShowFlag">
            确定
          </div>
        </div>
      </div>
      <div v-else>
        <div class="textVip">
          <span style="color:#69CD96;margin-right: 10px;">开通智能VIP</span>
          <span>查看完整数据</span>
        </div>
        <div class="btn" style="width: 47%;" @click="toVip">
          开通VIP
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {
  yxxzList,
  yxxzListZKP,
  education,
  zwhzbxList,
  xkpgList,
  dlqyList,
  csdjList,
  zslxList,
  zslxListZKP,
  educational,
  educationalZKP,
  collegeGenreList,
  xfbzList,
  sbdList
} from "@/utils/fillingList.js";
import { getDicts } from "@/api/system/dict/data.js";
import { provienceAndcityArea } from "@/utils/provience";
import {
  queryJobNameTB,
  queryLevelVo,
  getVolunteerResultPamas,
  setVolunteerResultPamas
} from "@/api/professionalinvestigation.js";
export default {
  name: 'intellDetails',
  mixins: [],
  components: {},
  props: ['ZZORZN','batchFile'],
  data() {
    return {
      yxxzList, //院校性质
      education, //院校层次
      educational, //院校特色
      collegeGenreList, //院校类型
      xkpgList, //学科评估
      csdjList, //城市等级
      dlqyList, //地理区域
      zslxList, //招生类型
      jyfxList: [], //就业方向
      zwhzbxList,   //中外合作办学
      provienceAndcityArea, //省市级二级联动
      xfbzList,    //学费标准
      sbdList,    //硕博点
      mqzpList: [],  //名企招聘
      levearr: [],
      jyfxpd: [],
      type: 1,
      customProps: {
        multiple: true, // 启用多选
        value: "professionalCode", // 自定义当前数组的键值 - value
        label: "subjectcategoriesName", // 自定义当前数组的键名 - label
        children: "majorPlusVos", // 自定义当前数组 - children
        expandTrigger: "hover", // 次级菜单的展开方式 - click/hover
      },
      areaNameType: "",
      gbnumbers: [],
      recommendSex: '',
      gnbunberDisable: false,
      fillmothodDisable: false,
      customPropsArea: {
        multiple: true, // 启用多选
        value: "name", // 自定义当前数组的键值 - value
        label: "name", // 自定义当前数组的键名 - label
        children: "child", // 自定义当前数组 - children
        expandTrigger: "hover", // 次级菜单的展开方式 - click/hover
      },
      majorCasckey: 0,
      originOfStudent: '河北省',
      znVipDialogShowFlag: false,
      userType: '',
      onlyZKShow:true   //专科批不显示一些筛选条件
    }
  },
  computed: {

  },
  watch: {
    levearr: {
      handler() {
        this.majorCasckey++;
      },
    },
    jyfxList: {
      handler() {
        this.jyfxList.forEach((item, index) => {
          if (this.jyfxpd.includes(String(item.jyfxId))) {
            this.jyfxList[0].show = false;
            this.jyfxList[index].show = true;
          }
        });
      }
    },
    batchFile:{
      deep: true,
      immediate:true,
       handler(newVal,oldVal) {
         this.watchBatchFile(newVal)
        //  this.infoOkChange('submit')

      }
    }
  },
  created() {
    // this.resetForm()

    // this.getzY()
  },
  mounted() {

    // 就业方向
    queryJobNameTB().then(res => {
      this.jyfxList = res.data.map((item) => {
        return { ...item, name: item.jyfx, show: false };
      });
      this.jyfxList.forEach((item, index) => {
        if (this.jyfxpd.includes(String(item.jyfxId))) {
          this.jyfxList[0].show = false;
          this.jyfxList[index].show = true;
        }
      });

      // 回显就业方向


    })
    // 名企招聘
    getDicts("sys_enterprise_recruitment").then((res) => {
      this.mqzpList = res.data.map((item) => {
        return { ...item, name: item.dictLabel, show: false };
      });
    });

  },
  methods: {
    // 监听批次发生变化执行方法
    watchBatchFile(batchFile){
       this.gnbunberDisable = false    //自选专业是否是 disabled
       this.fillmothodDisable = false   //专家推荐是否是 disabled
        if(batchFile == '专科批'){
              this.onlyZKShow = false
              this.zslxList = zslxListZKP
              this.yxxzList = yxxzListZKP
              this.educational = educationalZKP

              if(this.gbnumbers.length == 0){
                  this.getqueryLevelVo('专科（高职）')
              }

            }else if(batchFile == '本科批'){
              this.onlyZKShow = true
               this.zslxList = zslxList
              this.yxxzList = yxxzList
              this.educational = educational

               if(this.gbnumbers.length == 0){
                 this.getqueryLevelVo('本科')
              }

            }

    },
    getzY() {

      getVolunteerResultPamas({
        phoneNumbe: localStorage.getItem("phonenumber"),
        paramsType: this.ZZORZN == 'ZZ' ? 1 : 2
      }).then(res => {

        const { collegeNature, collegeLabel, collegeGenre, collegeType,
          recruitStudentsCategory, grade, cityLevel, collegeArea, jyfx
        } = res.data

        if (jyfx.length > 0) {
          this.gnbunberDisable = true
        }
        this.jyfxpd = jyfx
        // 封装基础文字的选项
        function forList(arry, arrlist) {
          arry.forEach((item, index) => {
            if (arrlist.includes(item.name)) {
              arry[0].show = false;
              arry[index].show = true;
            }
          });
        }
        //院校性质
        forList(this.yxxzList, collegeNature);
        //院校特色
        forList(this.educational, collegeLabel)
        // 院校类型
        forList(this.collegeGenreList, collegeGenre)
        // 院校层次
        forList(this.education, collegeType)
        // 招生类型
        forList(this.zslxList, recruitStudentsCategory)
        // 学科评估
        forList(this.xkpgList, grade)
        // 城市地区
        forList(this.csdjList, cityLevel)
        // 地理区域
        forList(this.dlqyList, collegeArea)
        // 就业方向
        setTimeout(() => {
          this.jyfxList.forEach((item, index) => {
            if (this.jyfxpd.includes(String(item.jyfxId))) {
              this.jyfxList[0].show = false;
              this.jyfxList[index].show = true;
            }
          });
        })

      })


    },



    // 每次筛选条件点击的时候
    changeActive(index, type, el) {

      if (type == "yxxz") {
        //院校性质
        if (index != 0) {
          this.yxxzList[0].show = false
          this.yxxzList[index].show = !this.yxxzList[index].show;
        } else {
          this.yxxzList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "yxcc") {

               if (index != 0) {
          this.education[0].show = false
          this.education[index].show = !this.education[index].show;
        } else {
          this.education.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }

      } else if (type == "yxts") {
        //院校特色
        if (index != 0) {
          this.educational[0].show = false
          this.educational[index].show = !this.educational[index].show;
        } else {
          this.educational.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "yxlx") {
        //院校类型

        if (index != 0) {
          this.collegeGenreList[0].show = false
          this.collegeGenreList[index].show = !this.collegeGenreList[index].show;
        } else {
          this.collegeGenreList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "xkpg") {
        //学科评估
        if (index != 0) {
          this.xkpgList[0].show = false
          this.xkpgList[index].show = !this.xkpgList[index].show;
        } else {
          this.xkpgList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "csdj") {
        //城市等级

        if (index != 0) {
          this.csdjList[0].show = false
          this.csdjList[index].show = !this.csdjList[index].show;
        } else {
          this.csdjList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "dlqy") {
        // 地理区域

        if (index != 0) {
          this.dlqyList[0].show = false
          this.dlqyList[index].show = !this.dlqyList[index].show;
        } else {
          this.dlqyList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "zslx") {
        //招生类型

        if (index != 0) {
          this.zslxList[0].show = false
          this.zslxList[index].show = !this.zslxList[index].show;
        } else {
          this.zslxList.forEach((item, index) => {
            if (index == 0) {
              item.show = true
            } else {
              item.show = false
            }
          })
        }
      } else if (type == "jyfx") {
        if (this.recommendSex || this.gbnumbers.length != 0) {
          let toast = this.ZZORZN == 'ZZ' ? '专业条件二选一，请清空某些数值' : '专业条件三选一，请清空某些数值'
          this.$message.warning(toast)
        } else {
          //就业方向
          this.jyfxList[index].show = !this.jyfxList[index].show;
          const isnoneShow = this.jyfxList.every(item => !item.show)
          if (isnoneShow) {
            this.fillmothodDisable = false
            this.gnbunberDisable = false
          } else {  //--------> 如果选择了就业方向，那么专业+专家推荐都不允许使用。
            this.recommendSex = ''
            this.fillmothodDisable = true
            this.gbnumbers = []
            this.gnbunberDisable = true;
          }
        }
      } else if (type == "xfbz") {       //只有智能填报有该筛选条件
        // 学费标准【多选】
        if (this.ZZORZN == 'ZZ') {
          this.znVipDialogShow()
        } else if (this.ZZORZN == 'ZN') {
          if (index != 0) {
            this.xfbzList[0].show = false
            this.xfbzList[index].show = !this.xfbzList[index].show;
          } else {
            this.xfbzList.forEach((item, index) => {
              if (index == 0) {
                item.show = true
              } else {
                item.show = false
              }
            })
          }
        }


      } else if (type == "sbd") {   //只有智能填报有该筛选条件
        // 硕博点
        if (this.ZZORZN == 'ZZ') {
          this.znVipDialogShow()
        } else if (this.ZZORZN == 'ZN') {
          if (index != 0) {
            this.sbdList[0].show = false
            this.sbdList[index].show = !this.sbdList[index].show;
          } else {
            this.sbdList.forEach((item, index) => {
              if (index == 0) {
                item.show = true
              } else {
                item.show = false
              }
            })
          }
        }


      } else if (type == "mqzp") {   //只有智能填报有该筛选条件
        // 名企招聘
        if (this.ZZORZN == 'ZZ') {
          this.znVipDialogShow()
        } else if (this.ZZORZN == 'ZN') {
          this.mqzpList[index].show = !this.mqzpList[index].show;
        }
      }
      if (el !== 'rms') {
        this.infoOkChange("ottr")
      }
    },
    // 自主点击高级筛选条件的时候，非自主筛选条件进行弹框展示
    znVipDialogShow() {
      this.userType = this.$store.state.userInfo.userType
      this.znVipDialogShowFlag = true;
    },
    // 专业名称的change事件--关闭其他两项的选择
    gbNumberChange(e) {
      if (e.length == 0) {
        this.fillmothodDisable = false;
      } else {
        this.fillmothodDisable = true;
        this.recommendSex = ''
        this.jyfxList.forEach(item => {
          item.show = false;
        })
      }
    },
    // 专家推荐的change事件--关闭其他两项的选择
    fillmothodChange(e) {
      if (e) {
        this.gnbunberDisable = true;
        this.gbnumbers = []
        this.jyfxList.forEach(item => {
          item.show = false;
        })
      } else {
        this.gnbunberDisable = false;
      }
    },
    // 获取专业名称
    getqueryLevelVo(level) {
      const self = this;
      const obj = {
        professionalLevel: level,
      };
      queryLevelVo(obj).then((res) => {
        if (res.code == 200) {
          this.gbnumbers = []
          res.data.forEach((item) => {
            item.majorPlusVos.forEach((itemm) => {
              itemm.professionalMajorVos.forEach((itemmm) => {
                self.$set(
                  itemmm,
                  "subjectcategoriesName",
                  itemmm.professionalName
                );
              });
              self.$set(itemm, "subjectcategoriesName", itemm.majorplusName);
              self.$set(itemm, "majorPlusVos", itemm.professionalMajorVos);
            });
          });
          self.levearr = res.data;
        }
      });
    },
    // 确定和返回按钮【智能填报】
    async infoOkChange(type) {
      let obj = {
        collegeLabel: this.educational.filter(item => item.show).map(item => item.name).join('|'),       //院校特色---[985/211]
        collegeNature: this.yxxzList.filter(item => item.show).map(item => item.name).join('|'),       //院校性质---[公办/民办/中外合作办学]
        collegeType: this.education.filter(item => item.show).map(item => item.name).join('|'),    //院校层次 【本科。专科】
        collegeGenre: this.collegeGenreList.filter(item => item.show).map(item => item.name).join('|'),   //院校类型【综合、理工、财经】
        gbnumbers: '',     //专业代码---[第三级专业的专业代码(010101|010102|010103K|010104T)]
        grade: this.xkpgList.filter(item => item.show).map(item => item.name).join('|'),  //学科评估结果
        collegeLocality: '',  //地区名称---[石家庄、衡水]
        collegeArea: this.dlqyList.filter(item => item.show).map(item => item.name).join('|'), //地理区域【华北、华东】
        cityLevel: this.csdjList.filter(item => item.show).map(item => item.name).join('|'),  //城市等级  【一线、二线】
        recruitStudentsCategory: this.zslxList.filter(item => item.show).map(item => item.name).join('|'), //招生类型
        jyfx: this.jyfxList.filter(item => item.show).map(item => item.jyfxId).join('|'),   //就业方向
        tuition: this.xfbzList.filter(item => item.show).map(item => item.name).join('|'),  //学费标准
        sbStation: this.sbdList.filter(item => item.show).map(item => item.name).join('|'),   //硕博点
        mqzp: this.mqzpList.filter(item => item.show).map(item => item.name).join('|'),  //名企招聘
        recommendSex: this.recommendSex || '3',    //专家推荐： 1：男  2：女 3：未选择
      };
      // 专业类别处理
      if (this.gbnumbers.length == 0) {
        obj.gbnumbers = "";
      } else {
        this.gbnumbers.forEach((item, index) => {
          obj.gbnumbers += item[2] + "|";
        });
      }
      // 如果专业类别最后一个字符串是'|',那么将最后一个字符串删除掉再传给后端
      if (obj.gbnumbers.toString().charAt(obj.gbnumbers.toString().length - 1) == "|") {
        obj.gbnumbers = obj.gbnumbers.toString().substr(0, obj.gbnumbers.toString().length - 1); // 截取该字符串的length-1
      }
      // 地区名称处理
      if (this.areaNameType.length == 0) {
        obj.collegeLocality = "";
      } else {
        this.areaNameType.forEach((item, index) => {
          obj.collegeLocality += item[1] + "|";
        });
      }
      // 如果地区名称最后一个字符串是'|',那么将最后一个字符串删除掉再传给后端
      if (obj.collegeLocality.toString().charAt(obj.collegeLocality.toString().length - 1) == "|") {
        obj.collegeLocality = obj.collegeLocality.toString().substr(0, obj.collegeLocality.toString().length - 1); // 截取该字符串的length-1
      }
      // 对collegeLabel处理，如果包含【双一流】，就加上 '|一流建设学科'
      if (obj.collegeLabel.includes('双一流')) {
        obj.collegeLabel = obj.collegeLabel + '|一流建设学科'
      }
       if (obj.collegeLabel.includes('原部委直属')) {
        obj.collegeLabel = obj.collegeLabel + '|原属'
      }
      // console.log('院校性质', this.yxxzList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('院校层次', this.education.filter(item => item.show)[0].name)
      // console.log('院校特色', this.educational.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('院校类型', this.collegeGenreList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('自选专业', obj.gbnumbers)
      // console.log('学科评估', this.xkpgList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('地区名称', this.areaNameType)
      // console.log('城市等级', this.csdjList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('地理区域', this.dlqyList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('招生类型', this.zslxList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('就业方向', this.jyfxList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('收费标准', this.xfbzList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('硕博点', this.sbdList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('名企招聘', this.mqzpList.filter(item => item.show).map(item => item.name).join('|')

      if (type == 'ottr') {
        await setVolunteerResultPamas({
          ...obj,
          batchFile: this.$route.query.selectedFruits3 || '本科批',
          subject: this.$store.state.userInfo.subject,
          score: this.$store.state.userInfo.score,
          userId: localStorage.getItem("phonenumber"),
          paramsType: this.ZZORZN == 'ZZ' ? 1 : 2,

        }).then(res => {

        })
      }else if(type == 'submit'){
           this.$emit('updataChild', obj)
      }
    },
    resetBx() {
      this.xfbzList.forEach((item, index) => {
        if (index == 0) {
          item.show = true
        } else {
          item.show = false
        }
      })
      this.sbdList.forEach((item, index) => {
        if (index == 0) {
          item.show = true
        } else {
          item.show = false
        }
      })
    },
    toVip() {
      this.znVipDialogShowFlag = false
      this.$router.push({ path: '/vip' })
    },
    handleClose() {
      this.znVipDialogShowFlag = false
    },
    // 重置表单
    resetForm() {
      console.log('重置表单')
      // ==========================================院校===============================================
      this.changeActive(0, 'yxxz', 'rms')  //院校性质
      this.changeActive(0, 'yxcc', 'rms')  //院校层次
      this.changeActive(0, 'yxts', 'rms')  //院校特色
      this.changeActive(0, 'yxlx', 'rms') //院校类型
      // ==========================================专业===============================================
      this.gbnumbers = [] // 自选专业
      this.jyfxList.forEach(item => item.show = false)  // 就业方向
      this.recommendSex = ''  //专家推荐
      this.gnbunberDisable = false
      this.fillmothodDisable = false
      // ==========================================地区===============================================
      this.areaNameType = []       //城市名称
      this.changeActive(0, 'csdj', 'rms')  //城市等级
      this.changeActive(0, 'dlqy', 'rms')  //地理区域
      // ==========================================高级===============================================
      this.changeActive(0, 'zslx', 'rms')    //招生类型
      this.changeActive(0, 'xkpg', 'rms') //学科评估
      // 如果是智能，置空三个特殊选项
      if (this.ZZORZN == 'ZN') {
        this.changeActive(0, 'xfbz', 'rms') //学费标准
        this.changeActive(0, 'sbd', 'rms')   //硕博点
        this.mqzpList.forEach(item => item.show = false) //名企招聘
      }
      if (this.ZZORZN == 'ZZ') {
        this.xfbzList.forEach(item => item.show = false)
        this.sbdList.forEach(item => item.show = false)
        this.mqzpList.forEach(item => item.show = false)
      }
    },
    getZSLXList(batchFile){
      // 如果路由存在填报批次，判断批次是本科皮还是专科批。
    // 本科批和专科批对应的招生类型内容不一致，所以需要判断。

      //  if(batchFile == '本科批' || !batchFile){
      //         this.zslxList = zslxList.map((item,index)=>{
      //           return {
      //             ...item,
      //             show:index == 0? true : false
      //           }
      //            })
      //   }else if(batchFile == '专科批'){
      //           this.zslxList = zslxListZK.map((item,index)=>{
      //           return {
      //             ...item,
      //             show:index == 0? true : false
      //           }
      //            })
      //   }

    }
  },
  activated() {
    // this.resetForm()
    // this.getzY()
    this.getZSLXList(this.$route.query.selectedFruits3)
  },

}
</script>

<style scoped lang="scss">
.searchTitleBox {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #12b098;

  .searchTitleItem {
    width: 115px;
    padding-left: 6px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    cursor: pointer;
    background: #F2F2F2;
    // margin: 0 5px;
    //  border-radius: 8px !important;
  }
}

.searchTitleBoxActive {
  border: none !important;
}

.infoToast {
  font-size: 12px;
  cursor: default;
}

.infoToast:hover {
  color: black;
}

.activeSearchTitle {
  background: #12b098 !important;
  color: white;
  // border-radius: 8px !important;
}

.active {
  background: #12b098 !important;
  color: white;
  border-radius: 8px !important;
}

.active:hover {
  color: white !important;
}

.searchTitle {
  flex: 1;
  padding-left: 15px;
}

.searchTitleZZ {
  flex: 8;
}

.searchTitleZN {
  flex: 6;
}

.slxtext {
  color: #c0c4cc;
  font-size: 13px;
  border: 1px solid #dbd9d9;
  padding: 3px;
  position: absolute;
  width: 520px;
  right: 0px;
  line-height: 18px;
}

.submitCss {
  height: 40px;
  background: #12b098;
  border-radius: 8px;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  margin: 15px 15px 0;
  cursor: pointer;
  width: 20%;
  display: inline-block
}
</style>
