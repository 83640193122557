 // 中外合作办学
 export const zwhzbxList = [{
   name: '不限',
   id: 1
 }, {
   name: '是',
   id: 2
 }, {
   name: '否',
   id: 3
 }]
 // 学科评估
 export const xkpgList = [{
     name: '不限',
     id: 1,
     show: true
   }, {
     name: 'A+',
     id: 2,
     show: false
   },
   {
     name: 'A',
     id: 3,
     show: false
   }, {
     name: 'A-',
     id: 4,
     show: false
   }, {
     name: 'B+',
     id: 5,
     show: false
   }, {
     name: 'B',
     id: 6,
     show: false
   },
   {
     name: 'B-',
     id: 7,
     show: false
   }, {
     name: 'C+',
     id: 8,
     show: false
   }, {
     name: 'C',
     id: 9,
     show: false
   }, {
     name: 'C-',
     id: 10,
     show: false
   },
 ]
 // 地理区域
 export const dlqyList = [{
     name: '不限',
     id: 1,
     show: true
   },
   {
     name: '东北地区',
     id: 2,
     show: false
   },
   {
     name: '西北地区',
     id: 3,
     show: false
   },
   {
     name: '华北地区',
     id: 4,
     show: false
   },
   {
     name: '华东地区',
     id: 5,
     show: false
   },
   {
     name: '华中地区',
     id: 6,
     show: false
   },
   {
     name: '西南地区',
     id: 7,
     show: false
   },
   {
     name: '东南地区',
     id: 8,
     show: false
   },
 ]
 // 城市等级
 export const csdjList = [{
     name: '不限',
     id: 1,
     show: true
   },
   {
     name: '一线城市',
     id: 2,
     show: false
   },
   {
     name: '新一线城市',
     id: 2.1,
     show: false
   },

   {
     name: '二线城市',
     id: 3,
     show: false
   },
   {
     name: '三线城市',
     id: 4,
     show: false
   },
   {
     name: '四线城市',
     id: 5,
     show: false
   },
   {
     name: '五线城市',
     id: 6,
     show: false
   },
 ]
 // 招生类型----本科批
 export const zslxList = [{
     name: '不限',
     id: 1,
     show: true
   },
   {
     name: '普通',
     id: 2,
     show: false
   },
   {
     name: '地方专项计划',
     id: 3,
     show: false
   },
  //  {
  //    name: '国家专项计划',
  //    id: 6,
  //    show: false
  //  },
   {
     name: '中外合作',
     id: 4,
     show: false
   },
   {
     name: '校企合作',
     id: 5,
     show: false
   },
 ]
//  招生类型----专科批
 export const zslxListZKP = [{
     name: '不限',
     id: 1,
     show: true
   },
   {
     name: '普通',
     id: 2,
     show: false
   },
 {
   name: '中外合作',
   id: 4,
   show: false
 }, {
   name: '校企合作',
   id: 5,
   show: false
 },


 ]
  // 招生类型【没有“不限"】
  export const zslxListCopy = [
    {
      name: '普通',
      id: 2,
      show: false
    },
    {
      name: '地方专项计划',
      id: 3,
      show: false
    },
    {
      name: '国家专项计划',
      id: 6,
      show: false
    },
    {
      name: '中外合作',
      id: 4,
      show: false
    },
    {
      name: '校企合作',
      id: 5,
      show: false
    },
    {
      name: '高校专项计划',
      id: 6,
      show: false
    },
    {
      name: '定向培养军士生',
      id: 7,
      show: false
    },
  ]

 export const cityList = [{
   "name": "北京",
   show: false
 }, {
   "name": "天津",
   show: false
 }, {
   "name": "河北",
   show: false
 }, {
   "name": "山西",
   show: false
 }, {
   "name": "内蒙古",
   show: false
 }, {
   "name": "辽宁",
   show: false
 }, {
   "name": "吉林",
   show: false
 }, {
   "name": "黑龙江",
   show: false
 }, {
   "name": "上海",
   show: false
 }, {
   "name": "江苏",
   show: false
 }, {
   "name": "浙江",
   show: false
 }, {
   "name": "安徽",
   show: false
 }, {
   "name": "福建",
   show: false
 }, {
   "name": "江西",
   show: false
 }, {
   "name": "山东",
   show: false
 }, {
   "name": "河南",
   show: false
 }, {
   "name": "湖北",
   show: false
 }, {
   "name": "湖南",
   show: false
 }, {
   "name": "广东",
   show: false
 }, {
   "name": "广西",
   show: false
 }, {
   "name": "海南",
   show: false
 }, {
   "name": "重庆",
   show: false
 }, {
   "name": "四川",
   show: false
 }, {
   "name": "贵州",
   show: false
 }, {
   "name": "云南",
   show: false
 }, {
   "name": "西藏",
   show: false
 }, {
   "name": "陕西",
   show: false
 }, {
   "name": "甘肃",
   show: false
 }, {
   "name": "青海",
   show: false
 }, {
   "name": "宁夏",
   show: false
 }, {
   "name": "新疆",
   show: false
 }, {
   "name": "台湾",
   show: false
 }, {
   "name": "澳门",
   show: false
 }, {
   "name": "香港",
   show: false
 }]

 // [院校层次]本科/专科
 export const education = [
  {
    name: '不限',
    show: true
  },
  {
   name: '本科',
   show: false
 }, {
   name: '专科',
   show: false
 }]
 // [院校特色]----本科批
 export const educational = [
  {
    name: '不限',
    show: true
  },
  {
     name: '985',
     show: false
   },
   {
     name: '211',
     show: false
   },
   {
     name: '双一流',
     show: false
   },
   {
     name: '省重点',
     show: false
   },
   {
     name: '国重点',
     show: false
   },
   {
     name: '教育部直属',
     show: false
   },
   {
     name: '中央部委直属',
     show: false
   },


 ]
//  [院校特色]----专科批
 export const educationalZKP = [{
     name: '不限',
     show: true
   },

   {
     name: '省重点',
     show: false
   },
   {
     name: '国重点',
     show: false
   },
   {
     name: '省属',
     show: false
   },
   {
     name: '原部委直属',
     show: false
   },
 {
   name: '高水平学校建设单位',
   show: false
 },
 {
   name: '高水平专业群建设单位',
   show: false
 },
 {
   name: '本专兼招',
   show: false
 },
 ]
 // [院校类型]
 export const collegeGenreList = [
  {
     name: "不限",
     id: 0,
     show: true
   },
   {
     name: "综合",
     id: 1,
     show: false
   },
   {
     name: "理工",
     id: 2,
     show: false
   },
   {
     name: "财经",
     id: 3,
     show: false
   },
   {
     name: "农林",
     id: 4,
     show: false
   },
   {
     name: "医药",
     id: 5,
     show: false
   },
   {
     name: "师范",
     id: 6,
     show: false
   },
   {
     name: "体育",
     id: 7,
     show: false
   },
   {
     name: "政法",
     id: 8,
     show: false
   },
   {
     name: "艺术",
     id: 9,
     show: false
   },
   {
     name: "民族",
     id: 10,
     show: false
   },
   {
     name: "军事",
     id: 11,
     show: false
   },
   {
     name: "语言",
     id: 12,
     show: false
   },
 ]
 // [院校性质]-------本科批
 export const yxxzList = [
  {
    name: '不限',
    show: true
  },
  {
   name: '公办',
   show: false
 }, {
   name: '民办',
   show: false
 }, {
   name: '中外合作办学',
   show: false
 }, {
   name: '内地与港澳台地区合作办学',
   show: false
 }]
//  [院校性质]-------专科批
export const yxxzListZKP = [{
    name: '不限',
    show: true
  },
  {
    name: '公办',
    show: false
  }, {
    name: '民办',
    show: false
  }, {
    name: '中外合作办学',
    show: false
  }
]
//  学费标准
export const xfbzList = [
  {name: '不限',show: true},
  // {name: '5千以内',show: false},
  {
    name: '1万以下',
    show: false
  },
  {
    name: '1万-2万',
    show: false
  },
  {
    name: '2万-5万',
    show: false
  },
  {
    name: '5万以上',
    show: false
  },
]
// 硕博点
export const sbdList = [
  {
    name: '不限',
    show: true
  }, {
    name: '硕士点',
    show: false
  },
  {
    name: '博士点',
    show: false
  }
]
