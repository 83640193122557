import {
  gkzyCommonParameters
} from "@/api/commonparams"
import store from '@/store'
//位次、招录时间、非会员是否可用、出分时间、会员开卡类型
export const getAlltime = (type) => {
  return new Promise(resolve=>{
      if (type == 1) { //查询位次
        gkzyCommonParameters({
          parameterType: '位次'
        }).then(res => {
          resolve(res.rows[0].parameterValue)
        })
      } else if (type == 2 || type == 4) { //高考出分【开始】
        gkzyCommonParameters({
          parameterType: '出分时间'
        }).then(res => {
          resolve(res.rows[0].parameterValue.split(',')[0])
        })
      } else if (type == 3 || type == 5) { //高考出分【结束】
        gkzyCommonParameters({
          parameterType: '出分时间'
        }).then(res => {
          resolve(res.rows[0].parameterValue.split(',')[1])
        })
      }else if(type == 9){ //查询高考时间【开始和结束都返回】
        gkzyCommonParameters({
          parameterType: '出分时间'
        }).then(res => {
          resolve(res.rows[0].parameterValue.split(','))
        })
      }else if (type == 6) {
        gkzyCommonParameters({
          parameterType: '招录时间'
        }).then(res => {
          resolve(res.rows[0].parameterValue)
        })
      }else if(type == 7){
          gkzyCommonParameters({
            parameterType: '非会员是否可用'
          }).then(res => {
            resolve(res.rows[0].parameterValue)
          })
      }else if(type == 8){
          gkzyCommonParameters({
            parameterType: '会员开卡类型'
          }).then(res => {
            resolve(res.rows)
          })
      }
  })
}
// else if (type == 4) { //高一高二非高考年禁止使用【开始】
// return new Date().getFullYear() + '/06/20'
// }
// else if (type == 5) { //高一高二非高考年禁止使用【结束】
//   return new Date().getFullYear() + '/08/15'
// }

// 如果当前时间在开始时间和结束时间范围之内，返回true。
export const isbetweenTimeflag = (currentTime, startTime, endTime) => {
  if (Date.parse(currentTime) < Date.parse(startTime)) {
    return false
  } else if (Date.parse(currentTime) > Date.parse(endTime)) {
    return false
  } else {
    return true
  }
}
//出分时间  06.20
export const getOutgoing_time = () => {
  const time = getAlltime(1)
  return time
}
//开始时间   06.25
export const getStart_time = () => {
  const time = getAlltime(2)
  return time
}
//结束时间1   10.30
export const getEnd_time = () => {
  const time = getAlltime(3)
  return time
}
//结束时间2  09.15
export const getSecondEndTime = () => {
  const time = getAlltime(4)
  return time
}
// 非会员 6月23日-8月2日 不让用  【智能和自主】
export const getTime0623 = () => {
  const time = getAlltime(5)
  return time
}
export const getTime0802 = () => {
  const time = getAlltime(6)
  return time
}
// 判断是否是填报期间,return true为填报期间，false为填报时间外。返回值是promise，需要await解一下。
export const isFillTime = async () => {
  const serverTime = new Date(store.state.serverTime);
  const nowDate = serverTime.getTime();
  const res = await gkzyCommonParameters({
    parameterType: '出分时间'
  })
  const startTimeCopy = res.rows[0].parameterValue.split(',')[0] //填报时间【开始】
  const endTimeCopy = res.rows[0].parameterValue.split(',')[1] //填报时间【结束】


  const startTime = new Date(startTimeCopy).getTime() //将开始时间转换为时间戳
  const endTime = new Date(endTimeCopy).getTime() //将结束时间转换为时间戳
  return nowDate >= startTime && nowDate <= endTime
}
