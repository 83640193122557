export default {
  message: {
    'hello': '你好 世界',
    'desc': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
    'loginAccount': '账户密码登录',
    'loginByPhone': '手机号登录',
    'account': '账户',
    'password': '密码',
    'autoLofin': '自动登录',
    'forgetPassword': '忘记密码',
    'loginBtn': '登录',
    'otherLoginWay': '其他方式登录',
    'register': '注册',
    'help': '帮助',
    'privacy': '隐私',
    'clause': '条款',
    'phone': '手机号',
    'code': '验证码',
    'getCode': '获取验证码',
    'content':'请输入内容',
    'successInfo': '切换成功',
     'date': '日期',
     'name': '姓名',
     'address': '地址',
    'title': '标题',

  }
};
