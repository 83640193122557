import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import localeen from 'element-ui/lib/locale/lang/en'
import localecn from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/css/font.css'
import { getDicts } from '@/api/system/dict/data'
import Pagination from '@/components/Pagination'
import intellDetails from '@/components/intellDetails'
import fillingInfo from '@/components/fillingInfo'
import JSEncrypt from 'jsencrypt'
import i18n from './assets/language/index';
import {
  getAlltime, isbetweenTimeflag, isFillTime
}
from '@/utils/time.js'
import {
  isChangeScoreOrSubject
}
from '@/utils/ruoyi.js'
import DictData from '@/components/DictData'
console.log(process.env.VUE_APP_BASE_API)
Vue.prototype.getDicts = getDicts // 查询字典公用方法
Vue.prototype.getAlltime = getAlltime //查询出分时间、招录时间等
Vue.prototype.isFillTime = isFillTime //查询出分时间、招录时间等

Vue.prototype.isbetweenTimeflag = isbetweenTimeflag
Vue.prototype.isChangeScoreOrSubject = isChangeScoreOrSubject   //判断是否可以修改分数、选科
// import 'lib-flexible'
// 刷新保存状态
if (sessionStorage.getItem('store')) {
  store.replaceState(
    Object.assign(
      {},
      store.state,
      JSON.parse(sessionStorage.getItem('store'))
    )
  )
  sessionStorage.removeItem('store')
}

// 监听，在页面刷新时将vuex里的信息保存到sessionStorage里
window.addEventListener('beforeunload', () => {
  sessionStorage.setItem('store', JSON.stringify(store.state))
})
// 字典数据
DictData.install()
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
})
Vue.component('Pagination', Pagination)
Vue.component('intellDetails', intellDetails)
Vue.component('fillingInfo', fillingInfo)
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
