import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  Notification,
  MessageBox,
  Message,
  Loading
} from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/weihu',
    redirect: '/index',
    component: () => import('@/views/notfound/index'),
    meta: {
      title: 'weihu',
      isShowTop: false,

    }
  },
  // menu
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index'),
    // component: resolve => require(['@/views/index'], resolve),
    meta: {
      title: '首页',
      menu: 0,
      isShowTop: true,
         keepAlive: true
    }
  },


  {
    path: '/zxxq',
    name: 'zxxq',
    component: () => import('@/components/zxxq'),
    meta: {
      title: '资讯详情',
      menu: 0,
      isShowTop: true,
    }
  },

  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/course'),
    meta: {
      title: '课程',
      menu: 0,
      isShowTop: true,
    }
  },
  {
    path: '/sousuo',
    name: 'sousuo',
    component: () => import('@/views/sousoView'),
    meta: {
      title: '首页搜索',
      menu: 0,
      isShowTop: true,
      keepAlive: true,
    }
  },
  {
    path: '/courseitem',
    name: 'courseitem',
    component: () => import('@/views/course/courseItem.vue'),
    meta: {
      title: '热门课程',
      menu: 0,
      isShowTop: true,
    }
  },
  {
    path: '/voluntaryform',
    name: 'voluntaryform',
    component: () => import('@/views/fillvolunteer/voluntaryform'),
    meta: {
      title: '填志愿-当前志愿表',
      menu: 1,
      btnkeymenu: 2,
      isShowTop: true
    }
  },
  {
    path: '/fillingZZ',
    name: 'fillingZZ',
    component: () => import('@/views/fillingZZ'),
    meta: {
      title: '自主填报',
      menu: undefined,
      isShowTop: true,
      keepAlive: true,
    }
  },
  {
    path: '/fillingZN',
    name: 'fillingZN',
    component: () => import('@/views/fillingZN'),
    meta: {
      title: '智能填报',
      menu: undefined,
      isShowTop: true,
      keepAlive: true,
    }
  },
  {
    path: '/indexSchool',
    name: 'indexSchool',
    component: () => import('@/views/fillingZN/indexSchool'),
    meta: {
      title: '智能填报---结果页面',
      menu: undefined,
      isShowTop: true,
    }
  },

  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service'),
    meta: {
      title: '服务',
      menu: 0,
        keepAlive: true,
      isShowTop: true,
    }
  },
  {
    path: '/serviceItem',
    name: 'service1',
    component: () => import('@/views/service/serviceItem.vue'),
    meta: {
      title: '服务1',
      menu: 0,
      isShowTop: true,
    }
  },
  {
    path: '/serviceItem2',
    name: 'service2',
    component: () => import('@/views/service/serviceItem2.vue'),
    meta: {
      title: '服务2',
      menu: 0,
      isShowTop: true,
    }
  },
  // 个人中心
  {
    path: '/individual',
    name: 'individual',
    component: () => import('@/views/individual/index.vue'),
    // redirect: '/individual1',
    meta: {
      title: '个人中心',
      menu: 0,
      isShowTop: true,
      keepAlive:true
    },
    children: [{
      path: '/individual1',
      name: 'individual1',
      component: () => import('@/views/individual/comzhx1.vue'),
      meta: {
        title: '个人中心',
        menu: 0,
        isShowTop: true,
      },
    },
    {
      path: '/individual2',
      name: 'individual2',
      component: () => import('@/views/individual/comzhx2.vue'),
      meta: {
        title: '志愿表',
        menu: 0,
        isShowTop: true,
      },
    },
    {
      path: '/individual3',
      name: 'individual3',
      component: () => import('@/views/individual/comzhx3.vue'),
      meta: {
        title: '订单中心',
        menu: 0,
        isShowTop: true,
      },
    },
    {
      path: '/host',
      name: 'host',
      component: () => import('@/views/individual/host.vue'),
      meta: {
        title: '历史记录',
        menu: 0,
        isShowTop: true,
      },
    },
    {
      path: '/aboutUs',
       name: 'aboutUs',
         component: () => import('@/views/individual/aboutUs.vue'),
         meta: {
           title: '关于我们',
           menu: 0,
           isShowTop: true,
         },
    }
    ]
  },
  // vip
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/becomeVip'),
    meta: {
      title: 'VIP会员',
      menu: 0,
      isShowTop: true,
    },
    children: [
      {
        path: '/vip',
        name: 'vip',
        component: () => import('@/views/becomeVip/becomeVip1.vue'),
        meta: {
          title: 'vip1',
          menu: 0,
          isShowTop: true,
          isShowNo: 1
        },
      },
      {
        path: '/vip2',
        name: 'vip2',
        component: () => import('@/views/becomeVip/becomeVip2.vue'),
        meta: {
          title: 'vip2',
          menu: 0,
          isShowTop: true,
          isShowNo: 2
        },
      },
      {
        path: '/vip3',
        name: 'vip3',
        component: () => import('@/views/becomeVip/becomeVip3.vue'),
        meta: {
          title: 'vip3',
          menu: 0,
          isShowTop: true,
          isShowNo: 3
        },
      },
    ]
  },
  {
    path: '/UniversityRankings',
    name: 'UniversityRankings',
    component: () => import('@/views/UniversityRankings'),
    // component: resolve => require(['@/views/UniversityRankings'], resolve),
    meta: {
      title: '高校排名',
      menu: 0,
      isShowTop: true
    }
  },
  // 测一测
  {
    path: '/takeatest',
    name: 'takeatest',
    component: () => import('@/views/takeatest/listIndex'),
    meta: {
      title: '测一测',
      menu: 0,
      isShowTop: true,
      isFootercom: true,
      logoif: true
    }
  }, {
    path: '/takeatestItem',
    name: 'takeatestItem',
    component: () => import('@/views/takeatest'),
    meta: {
      title: '测一测内容',
      menu: 0,
      isShowTop: true,
      isFootercom: true,
      logoif: true
    }
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: () => import('@/views/UserAgreement'),
    // component: resolve => require(['@/views/UniversityRankings'], resolve),
    meta: {
      title: '用户协议',
      isShowTop: false
    }
  },

  {
    path: '/indexother',
    name: 'indexother',
    component: () => import('@/views/indexother'),
    meta: {
      title: '首页搜索模块',
      menu: 2,
      keepAlive: true,
      isShowTop: true
    },
    children: [
      {
        path: '/checkschools',
        name: 'checkschools',
        component: () => import('@/views/checkschools'),
        meta: {
          title: '查学校',
          menu: 1,
          keepAlive: true,
          isShowTop: true
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/checkschools/profile'),
        meta: {
          title: '查学校-学校概况',
          menu: 1,
          isShowTop: true,
          isShowrouterboxFlag:true
        }
      },
      {
        path: '/professionalinvestigation',
        name: 'professionalinvestigation',
        component: () => import('@/views/professionalinvestigation'),
        // component: resolve => require(['@/views/professionalinvestigation'], resolve),
        meta: {
          title: '查专业',
          menu: 2,
          keepAlive: true,
          isShowTop: true
        }
      },
      {
        path: '/goprofessionDetail',
        name: 'goprofessionDetail',
        component: () => import('@/views/professionalinvestigation/goprofessionDetail'),
        meta: {
          title: '专业详情',
          menu: 2,
          isShowTop: true,
          isShowrouterboxFlag:true,
        }
      },
      {
        path: '/Onebyone',
        name: 'Onebyone',
        component: () => import('@/views/Onebyone'),
        meta: {
          title: '一分一档',
          menu: 3,
          isShowTop: true
        }
      },
      {

        path: '/occupation',
        name: 'occupation',
        component: () => import('@/views/occupation'),
        meta: {
          title: '查职业',
          menu: 4,
          keepAlive: true,
          isShowTop: true
        }
      },
      {
        path: '/occupationDetail',
        name: 'occupationDetail',
        component: () => import('@/views/occupation/occupationDetail'),
        meta: {
          title: '查职业-----详情',
          menu: 4,
          keepAlive: false,
          isShowTop: true,
          isShowrouterboxFlag:true
        }

      },
      {
        path: '/students',
        name: 'students',
        component: () => import('@/views/students'),
        meta: {
          title: '招生计划',
          menu: 5,
          keepAlive: true,
          isShowTop: true
        }
      },

      {
        path: '/advance',
        name: 'advance',
        component: () => import('@/views/advance'),
        meta: {
          title: '提前批',
          menu: 6,
          isShowTop: true
        }
      },
      {
        path: '/curriculumJudgement',
        name: 'curriculumJudgement',
        component: () => import('@/views/curriculumJudgement'),
        meta: {
          title: '学科评估',
          menu: 7,
          keepAlive: true,
          isShowTop: true
        }
      }, {
        path: '/Batchline',
        name: 'Batchline',
        component: () => import('@/views/Batchline'),
        meta: {
          title: '批次线',
          menu: 8,
          keepAlive: true,
          isShowTop: true
        }
      },
    ]
  },


  {
    path: '/examinationdata',
    name: 'examinationdata',
    component: () => import('@/views/examinationdata'),
    meta: {
      title: '高考资料',
      menu: 4,
      isShowTop: true
    }
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      // if (to.path == '/professionalinvestigation') {
      //   return {
      //     x: 0,
      //     y: Number(to.query.scrollTop)
      //   }
      // } else {
      //   return {
      //     x: 0,
      //     y: 0
      //   }
      // }
    }
  }

})

router.beforeEach( (to, from, next) => {

  // await store.dispatch('setUserinfo')  //获取用户信息
  if ((to.path == '/individual1' || to.path == '/individual2' || to.path == '/individual3') && !localStorage.getItem('phonenumber')) {
    next('/')
  } else if ((to.path == '/fillingZZ' || to.path == '/fillingZN') && !localStorage.getItem('phonenumber')) {
    // Message.error('请登录')
     const channel = new BroadcastChannel('share')
     channel.postMessage(true)
    next('/')
  } else if (to.path == '/advance' || to.path == '/students' || to.path == '/takeatestItem') {
    if (!localStorage.getItem('phonenumber')) {
      const channel = new BroadcastChannel('share')
      channel.postMessage(true)
      // Message.error('请登录')
      next('/')
    } else {
      if (store.state.userInfo.userType == '01') {
        Message.error('普通用户无权限访问,请开通vip')
        next('/vip')
      } else {
        next()
      }
    }
  } else if ((to.path == '/vip' || to.path == '/vip2' || to.path == '/vip3') ) {
    if (!localStorage.getItem('phonenumber')){
      //  Message.error('请登录')
       const channel = new BroadcastChannel('share')
       channel.postMessage(true)
       next(false)
    }else{
       if (store.state.userInfo.userType == '05'){
               Message.success('您已是智能用户')
                next(false)
       }else{
        next()
       }
    }
  }else {
    next()
  }
})
// 防止连续点击多次路由报错
// 解决：Uncaught (in promise) NavigationDuplicated;
const originalPush = VueRouter.prototype.push // 先保存一份 VueRouter.prototype.push方法
const originalRepace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  // call(); 篡改上下文    catch(); 捕获异常 ****
  return originalPush.call(this, location).catch(e => e)
}
VueRouter.prototype.replace = function replace(location) {
  return originalRepace.call(this, location).catch(e => e)
}
export default router
