<template>
  <div class="pay" v-if="isShow">
    <div class="payIndex">
      <div class="top">
        <div class="title">
          成功领取VIP权益，马上付费激活使用
          <i @click="close" style="
              color: white;
              font-size: 23px;
              float: right;
              margin-right: -62px;
            " class="el-icon-circle-close"></i>
        </div>
        <p style="
            color: rgb(242, 206, 135);
            font-size: 14px;
            margin-bottom: 0px;
            height: 28px;
          ">
          累计超千万人已开通vip权益
        </p>
        <!-- <div class="pay-right-text-box" @click="isBuy = !isBuy">
          <p v-if="isBuy">激活码开通</p>
          <p v-else>返回套餐页面</p>
        </div> -->
      </div>
      <div class="bottom">
        <div style="display: flex; justify-content: space-between">
          <div class="left">
            <div style="
                display: flex;
                font-size: 18px;
                font-weight: 900;
                justify-content: space-around;
              ">
              <p style="color: #e0b155">开通vip</p>
              <p>精准选大学</p>
            </div>
            <div style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
              ">
              <div v-for="(item, index) in list" :key="index" class="itemDiv">
                <img :src="item.imgSrc" alt />
                <p>{{ item.titleOne }}</p>
                <h2>{{ item.titleTwo }}</h2>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="rightOne">
              <div style="display: flex; justify-content: space-between;width: 600px;padding-left: 5px;">
                <h1 style="font-weight: 900; margin-bottom: 10px; font-size: 18px">
                  志愿卡类型
                </h1>
                <el-select v-model="cardType" @change="checkType">
                  <el-option label="自主卡" value="自主卡"></el-option>
                  <el-option label="智能卡" value="智能卡"></el-option>
                </el-select>
                <!-- <el-select v-model="price" :disabled="true">
                     <el-option :label="item.parameterTitle" :value="item.parameterValue" v-for="(item,index) in VipList" :key="index"></el-option>
                </el-select> -->
              </div>
			  <div style="display: flex; justify-content: space-between;width: 600px;top: 10px;padding-top: 10px;padding-left: 5px;">
			    <h1 style="font-weight: 900; margin-bottom: 10px; font-size: 18px;">
			      志愿填报高级卡
			    </h1>
			    <el-select v-model="grade" @change="gradeChange">
			      <el-option label="高三" value="高三"></el-option>
			      <el-option label="高二" value="高二"></el-option>
			      <el-option label="高一" value="高一"></el-option>
			    </el-select>
			    <!-- <el-select v-model="price" :disabled="true">
			         <el-option :label="item.parameterTitle" :value="item.parameterValue" v-for="(item,index) in VipList" :key="index"></el-option>
			    </el-select> -->
			  </div>
              <div style="display: flex; justify-content: space-between;width: 600px;padding-left: 5px;">
                <p>
                  <i class="el-icon-circle-check" style="color: orange; font-size: 16px"></i>
                  模拟报志愿（普通类）
                </p>
                <p>
                  ￥
                  <span style="font-size: 30px; margin-left: 3px; color: orange;" v-bind:price="price">{{ price }}</span>
                </p>
              </div>
              <div style="display: flex; justify-content: space-between;padding-left: 5px;">
                <p>
                  使用有效期至：<span style="color: orangered">{{
                    expirationTime
                  }}</span>
                  <span style="font-size: 12px; font-weight: bold">(在当前年非高三学生在填报志愿期间不可使用)</span>
                </p>
                <img style="width: 41px" src="../assets/imgs/badge.png" alt />
              </div>
            </div>
            <div v-show="isBuy" class="rightTwo">
              <h1 style="font-weight: 900; margin-bottom: 10px; font-size: 18px">
                支付方式
              </h1>
              <div class="twoDiv" :class="paytitle == '微信支付' ? 'activeBorder' : ''" @click="wxpay">
                <img class="payImg" src="../assets/imgs/wechatPay.png" alt />
                <span>微信支付</span>
                <div style="float: right;">
                  <p>微信扫码</p>
                  <p>快捷支付</p>
                </div>
              </div>
              <div class="twoDiv" :class="paytitle == '支付宝支付' ? 'activeBorder' : ''" @click="zfbpay">
                <img class="payImg" src="../assets/imgs/zfbPay.png" alt />
                <span>支付宝支付</span>
                <div style="float: right;">
                  <p>支付宝扫码</p>
                  <p>快捷支付</p>
                </div>
              </div>
              <div class="twoDiv" :class="paytitle == '卡密支付' ? 'activeBorder' : ''" @click="cardpay">
                <img class="payImg" src="../assets/imgs/ka.png" alt />
                <span>卡密支付</span>
                <div style="float: right;">
                  <p>输入卡密</p>
                  <p>快捷支付</p>
                </div>
              </div>
            </div>

            <div class="rightThree">
              <div v-for="(item, index) in titleList" :key="index" style="
                  display: flex;
                  margin-bottom: 15px;
                  height: 50px;
                  line-height: 20px;
                ">
                <p style="
                    flex: 3;
                    margin-bottom: 12px;
                    font-weight: 900;
                    font-size: 17px;
                  ">
                  {{ item.title }}
                </p>
                <p style="flex: 12; color: #666; font-size: 15px">
                  {{ item.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :close-on-press-escape="false" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="true" title="支付提示" :visible.sync="centerDialogVisible" width="30%" center @close="dialogClose">
      <div style="text-align:center">
        <!-- 微信 -->
        <div v-show="paytitle == '微信支付'" style="height:275px">
          <div v-loading="loading" style="width: 210px; height: 210px;margin: 0 auto;">
            <div v-if="payTimeFlag" style="padding-left: 24px">
              剩余支付时间
              <span style="color: orange">{{ count }}</span>
            </div>
            <div style="text-align:center ;" v-if="payTimeFlag" ref="qrCode" id="qrCode"></div>
            <div v-if="resetPayflag" style="
                      z-index: 10;
                      width: 210px;
                      height: 210px;
                      background: rgba(0, 0, 0, 0.7);
                      color: white;
                      text-align: center;
                      cursor: pointer;
                      color: white;
                      box-sizing: border-box;
                      padding-top: 80px;
                    ">
              <p>二维码已过期</p>
              <p>点击重新刷新</p>
            </div>
            <div style="margin-top: 10px;">
              <img src="../assets/imgs/sao.png" style="width:20px ;vertical-align: middle;" alt="">
              <span>微信扫一扫支付</span>
            </div>
          </div>
        </div>
        <!-- 支付宝 -->
        <div v-show="paytitle == '支付宝支付'" style="height:275px">
          <div v-loading="loadingZFB" style="width: 210px; height: 210px;margin:auto">
            <iframe :srcdoc="qianHtml" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" width="210" height="210" style="overflow:hidden;">
            </iframe>
          </div>
          <div style="margin-top: 10px;">
            <img src="../assets/imgs/sao.png" style="width:20px ;vertical-align: middle;" alt="">
            <span>支付宝扫一扫支付</span>
          </div>
        </div>
        <!-- 激活码 -->
        <div v-show="paytitle == '卡密支付'">
          <div class="right-conversion">
            <h1 style="font-weight: 900; margin-bottom: 10px; font-size: 18px">
              激活码激活
            </h1>
            <div class="center">
              <el-input v-model="activationCode" placeholder="请输入激活码"></el-input>
              <div class="cell">
                <el-input v-model="validCode" placeholder="请输入验证码"></el-input>
                <div @click="changeCode" class="code">{{ fakeCode }}</div>
              </div>
              <el-button @click="activateFN">立即激活</el-button>
            </div>
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { REQWXPAY, REQZFBPAY, tradeQuery, activateCode, tradeQueryZFB } from '@/api/pay.js'
import QRCode from 'qrcodejs2'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      paytitle: '',
      centerDialogVisible: false,
      isBuy: true,
      activationCode: '',
      validCode: '',
      fakeCode: '',
      price: 299.00,
      grade: '',
	  cardType: '',
      qianHtml: '',
      loading: false,
      loadingZFB: false,
      isShow: false,
      expirationTime: '--------',
      list: [
        {
          imgSrc: require('../assets/imgs/payLeftOne.png'),
          titleOne: '科学梯度报志愿',
          titleTwo: '定制你的专属志愿'
        },
        {
          imgSrc: require('../assets/imgs/payLeftOne.png'),
          titleOne: '填报不再在是难题',
          titleTwo: '志愿表专家解读/建议'
        },
        {
          imgSrc: require('../assets/imgs/payLeftOne.png'),
          titleOne: '历年最全招录数据',
          titleTwo: '推荐冲击/稳妥/保底院校'
        },
        {
          imgSrc: require('../assets/imgs/payLeftOne.png'),
          titleOne: '合理选大学',
          titleTwo: '院校、专业录取率评估'
        }
      ],
      titleList: [
        {
          title: ' 1. 成绩修改：',
          content:
            '高考出分前，在当前地区及选科下，分数可任意修改。高考出分后，仅允许修改两次分数，科目不允许修改。'
        },
        {
          title: '2. 适用对象：',
          content:
            '河北省新高考选科类考生（艺术类和体育类考生除外)。'
        },
        {
          title: '3. 适用平台：',
          content:
            '使用购买账号(手机号)登录指南针智填、指南针智填小程序，都可以享受模拟报志愿VIP服务。'
        },
        {
          title: '4. 温馨提示：',
          content:
            '本系统仅提供志愿填报参考服务，招生计划与录取数据请以学校官方或考试院公布为准。正式填报请前往考试院官网，并根据官方信息，做好志愿核对。'
        }
      ],
      timer: null, // 轮询查询订单
      paytimer: null, // 5分钟倒计时的timer
      timerZFB: null, // 支付宝轮询
      orderNo: '',
      orderNoZFB: '',
      seconds: 300, // 5分钟的秒数
      count: '05分00秒',
      payTimeFlag: false,
      resetPayflag: false,
      VipList:[],
    }
  },
  computed: {},
  watch: {
    isShow (val) {
      if (val) {
        this.getMoRen()
      }
    }
  },
  created () {
    this.changeCode()
    // this.titleList[1].content = `有效期截至${this.expirationTime}`
    this.getMoRen()
  },
  mounted () { },
  methods: {
    async getMoRen () {
      if (!this.$store.state.userInfo.studentClass) {
        await this.$store.dispatch('setUserinfo')
      }
      this.grade = JSON.parse(JSON.stringify(this.$store.state.userInfo.studentClass))
      this.VipList = await this.getAlltime(8)
      // this.price = this.VipList[0].parameterValue
      this.gradeChange(this.grade)
	   this.cardType='自助卡'
    },
    dialogClose () {
      this.qianHtml = ''
      this.clearAllTimer()
    },
    // 卡密支付
    cardpay () {
      this.$confirm(`您当前选择的年级为${this.grade}，会员到期时间为${this.expirationTime}，是否继续`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.paytitle = '卡密支付'
        this.centerDialogVisible = true
      }).catch(() => {
      })
    },
    // 支付宝支付
    zfbpay () {
      this.$confirm(`您当前选择的年级为${this.grade}，会员到期时间为${this.expirationTime}，是否继续`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const self = this
        this.paytitle = '支付宝支付'
        this.loadingZFB = true
        this.centerDialogVisible = true
        const obj = {
          phoneNumber: localStorage.getItem('phonenumber'),
          productTitle: '支付宝购买',
          totalAmount: this.price,
          // totalAmount: '0.01',
          payType: '支付宝支付',
          expirationTime: this.expirationTime
        }
        await REQZFBPAY(obj).then((res) => {
          self.loadingZFB = false
          self.qianHtml = res.data.iframe
          self.orderNoZFB = res.data.outTradeNo
          return
          var form = res.data
          const div = document.createElement('div') // 创建div
          div.innerHTML = form // 将返回的form 放入div
          document.body.appendChild(div)
          document.forms[1].setAttribute('target', '_blank') // 新开窗口跳转
          document.forms[1].submit()
        })
        if (!this.timerZFB) {
          this.timerZFB = window.setInterval(async () => {
            const res = await tradeQueryZFB({ orderNo: this.orderNoZFB })
            if (res.data.status == 'TRADE_SUCCESS') {
              this.clearAllTimer()
              this.$message.success('支付成功')
              // 支付成功之后，获取个人信息状态。
              self.$store.dispatch('setUserinfo')
              this.centerDialogVisible = false
              this.isShow = false
            }
          }, 1000)
        }
      }).catch(() => {

      })
    },
    // 微信支付
    wxpay () {
      const self = this
      this.$confirm(`您当前选择的年级为${this.grade}，会员到期时间为${this.expirationTime}，是否继续`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.centerDialogVisible = true
        this.paytitle = '微信支付'
        this.loading = true
        this.resetPayflag = false
        this.payTimeFlag = true
        const obj = {
          phoneNumber: localStorage.getItem('phonenumber'),
          productTitle: this.cardType,
          totalAmount: this.price,
          // totalAmount: '0.01',
          payType: '微信支付',
          expirationTime: this.expirationTime
        }
        await REQWXPAY(obj).then((res) => {
          self.loading = false
          self.seconds = 300
          self.Time()
          self.$nextTick(() => {
            const el = this.$refs.qrCode
            document.getElementById('qrCode').innerHTML = ''
            new QRCode(el, {
              width: 200,
              height: 200,
              text: res.data.codeUrl
            })
          })
          self.orderNo = res.data.orderNo
        })
        if (!this.timer) {
          this.timer = window.setInterval(async () => {
            const res = await tradeQuery({ orderNo: self.orderNo })
            if (res.code == 200) {
              this.clearAllTimer()
              this.$message.success('支付成功')
              // 支付成功之后，获取个人信息状态。
              self.$store.dispatch('setUserinfo')
              this.centerDialogVisible = false
              this.isShow = false
              this.count = '05分00秒'
              this.payTimeFlag = false
            }
          }, 1000)
        }
      }).catch(() => {

      })
    },
    // 天 时 分 秒 格式化函数
    countDown () {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? '0' + d : d
      let h = parseInt((this.seconds / (60 * 60)) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.count = m + '分' + s + '秒'
      if (this.count == '00分00秒') {
        this.clearAllTimer()
        document.getElementById('qrCode').innerHTML = ''
        this.resetPayflag = true
        this.payTimeFlag = false
        this.loading = false
      }
    },
    // 定时器每过1秒参数减1
    Time () {
      this.paytimer = window.setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    // 清除所有计时器的公用方法
    clearAllTimer () {
      window.clearInterval(this.timer)
      this.timer = null
      window.clearInterval(this.paytimer)
      this.paytimer = null
      window.clearInterval(this.timerZFB)
      this.timerZFB = null
    },
    // 点击关闭按钮，清除查询订单计数器  清除5分钟倒计时
    close () {
      this.clearAllTimer()
      this.isShow = false
      this.count = '05分00秒'
      this.payTimeFlag = false
    },

    // 年级发生变化
    gradeChange (val) {
       let serverTime = new Date(this.$store.state.serverTime)
	   //获取当前时间--看下是否是服务器时间
	   let now = new Date();
	   let target = new Date(serverTime.getFullYear()+'-09-30 23:59:59');
	   //判断当前时间是否在指定的过期时间之前
	   let isBefore = now.getTime()< target.getTime();
	   //定义变量存储设定的年份
	   let userYear="";
	  if(isBefore){
		//过期之前
		userYear=serverTime.getFullYear();
		}else{
		//过期时间之后
		userYear=serverTime.getFullYear()+1;
	 }

      if (val == '高三') {
        this.expirationTime = userYear + '-09-30 23:59:59'
      } else if (val == '高二') {
        this.expirationTime = userYear + 1 + '-09-30 23:59:59'
      } else if (val == '高一') {
        this.expirationTime = userYear + 2 + '-09-30 23:59:59'
      }
    },
	//--2023年11月15日新加---
	checkType(val){
		if(val == "自主卡"){
			this.price =299
		}else{
			this.price =599
		}

	},
    // 激活码激活
    async activateFN () {
      // this.activationCode
      // this.validCode
      if (!this.activationCode) {
        return this.$message({
          message: '请输入激活码',
          type: 'warning'
        })
      }
      if (!this.validCode) {
        return this.$message({
          message: '请输入验证码',
          type: 'warning'
        })
      }
      if (this.validCode !== this.fakeCode) {
        this.validCode = ''
        this.changeCode()
        return this.$message({
          message: '验证码错误',
          type: 'error'
        })
      }
      const { code, msg } = await activateCode({
        expirationTime: this.expirationTime,
        codeNumber: this.activationCode,
        phoneNumber: localStorage.getItem('phonenumber')
      })
      if (code === 200) {
        this.$message({
          message: msg,
          type: 'success'
        })
        this.$store.dispatch('setUserinfo')
        this.centerDialogVisible = false
        this.isShow = false

        this.clearAllTimer()
      } else {
        this.$message({
          message: msg,
          type: 'error'
        })
      }
    },
    // 更新假验证码
    changeCode () {
      this.generateRandomString(4)
    },
    // 生成随机字符串
    generateRandomString (length) {
      const charSet =
        'abcdefghijklmnopqrstuvwxyz0123456789'
      let randomString = ''
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charSet.length)
        randomString += charSet.charAt(randomIndex)
      }
      this.fakeCode = randomString
    }

  }
}
</script>

<style scoped lang="scss">
.pay {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.payIndex {
  width: 975px;
  margin: 0 auto;
  height: 100%;

  .top {
    color: #ffd27f;
    background: url("../assets/imgs/payBg.png") no-repeat;
    background-size: cover;
    height: 80px;
    padding-top: 10px;
    padding-left: 24px;
    padding-right: 72px;
    font-size: 16px;
    position: relative;

    .title {
      font-size: 18px;
      font-weight: 700;
      height: 28px;
    }

    .pay-right-text-box {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 20px;
      cursor: pointer;
      user-select: none;
    }
  }

  .bottom {
    padding: 16px;
    display: flex;
    height: 580px;

    background: #f5f6fa;

    .left {
      line-height: 1;
      border-radius: 12px;
      width: 212px;
      height: 520px;
      padding: 16px;
      color: #111;
      background-color: #fff;
    }

    .itemDiv {
      text-align: center;

      p {
        font-size: 15px;
        margin: 10px 0px;
      }

      img {
        height: 48px;
        width: 48px;
      }

      h2 {
        font-size: 18px;
        font-weight: 900;
      }
    }

    .right {
      width: 656px;
      margin-left: 20px;

      .rightThree,
      .rightTwo,
      .rightOne {
        background-color: #fff;
        border-radius: 12px;
        padding: 12px 5px 12px;
      }

      .rightOne {
        padding-right: 0px;
        padding-bottom: 0px;
        border: 2px solid #ff6500;
      }

      .rightTwo {
        margin: 5px 0px;
        overflow: hidden;
        .activeBorder {
          border: 2px solid #ff6500 !important;
        }

        .twoDiv {
          position: relative;
          float: left;

          width: 31%;
          font-size: 13px;
          margin: 0 5px;

          box-sizing: border-box;
          padding: 10px;
          border: 1px solid #ccc;
          .saomaImg {
            display: block;
            width: 150px;
          }

          span {
            cursor: pointer;

            margin: 0px 10px 10px;
          }

          .payImg {
            width: 26px;
            height: 26px;
            vertical-align: middle;
          }
        }
      }

      .rightThree {
        min-height: 212px;
        font-size: 14px;

        line-height: 1;
        color: #111;
      }
    }
  }
}
.right-conversion {
  box-sizing: border-box;
  width: 106%;
  background-color: #fff;
  border-radius: 12px;

  .center {
    width: 60%;
    margin: 40px auto 0;
    text-align: center;
    .el-button {
      width: 100%;
    }
    .el-input {
      margin-bottom: 20px;
    }
    .cell {
      display: flex;
      .code {
        height: 40px;
        width: 100px;
        background-color: orange;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>
