import request from '@/utils/request'

// 根据类型查询数据   位次、招录时间、非会员是否可用、出分时间、会员开卡类型
export function gkzyCommonParameters(query) {
  return request({
    url: '/system/gkzyCommonParameters/queryByList',
    method: 'get',
    params: query
  })
}
// 编辑 /dev/api
export function gkzyCommonParametersedit(query) {
  return request({
    url: '/system/gkzyCommonParameters/edit',
    method: 'post',
    data: query
  })
}
