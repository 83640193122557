<template>
  <div>
    <div class="cont">
      <div v-for="item in imgList " :key="item.value" class="contItem" :style="{height:item.isSpecailHeight ? '60px': '50px',marginTop:item.isSpecaiMar ? '20px' : '10px'}" @click="tanc(item)">
        <img :src="item.img" alt=""><br>
        <span>{{ item.value }}</span>
      </div>

    </div>
    <span class="top" @click="toTop">顶部</span>
    <img class="imggg" @click="$router.push('/vip')" src="@/assets/imgs/gg.png" alt="">

    <div class="tc" v-if="dialogVisible">
      <div style="font-size: 30px;float: right;margin-right: 10px;cursor: pointer;" @click="gub">x</div>
      <img :src="imgs" alt="">
    </div>
    <div class="overlay " v-if="dialogVisible"></div>
  </div>
</template>


<script>
import kf from '../assets/imgs/kf.png'
import wx from '../assets/imgs/wx.png'
import xcx from '../assets/imgs/xcx.png'
import zd from '../assets/imgs/zd.png'
import xcxc from '../assets/imgs/xcx.jpg'
import wxgz from '../assets/imgs/GZH.jpg'
import fh from '../assets/imgs/fh.png'







export default {
    data() {
        return {
            dialogVisible: false,
            imgs: xcxc,
            imgList: [
                {
                    img: fh,
                    value: '返回',
                    showIndex:4,
                },
                {
                    img: xcx,
                    value: '小程序',
                    showIndex:1,
                }, {
                    img: zd,
                    value: '操作      指导',
                    showIndex:2,
                    isSpecailHeight:true
                },{
                  img:wx,
                   value: '微信',
                    showIndex:3,
                }

              ]
        };
    },
    methods: {


        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        },
        tanc(item) {
            if (item.showIndex == 1) {
                this.imgs = xcxc
                this.dialogVisible = true
            }
            if (item.showIndex == 3) {
                this.imgs = wxgz
                this.dialogVisible = true
            }
            if (item.showIndex == 2) {
                this.$router.push({
                    path: '/examinationdata',
                })
            }
            if (item.showIndex == 4) {
                this.$router.go(-1)
            }
        },
        gub() {
            this.dialogVisible = false

        }
    },
}
</script>

<style lang="scss" scoped>
.cont {
    width: 30px;

    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 0 5px #d3d3d3;
    position: relative;
    cursor: pointer;
   padding: 7px 6px 46px;

}

.contItem {
    position: relative;
    height: 50px;
    cursor: pointer;
    margin-top: 10px;
    box-sizing: border-box;
    text-align: center;
    img {
        // position: absolute;
        width: 60%;
        top: 18%;
        cursor: pointer;
        left: 18%;
        width: 24px;
        height: 24px;
    }

    span {
        // width: 80%;
        display: block;
        font-size: 10px;
        // position: absolute;
        top: 65%;
        left: 9%;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(201, 201, 201);
        cursor: pointer;

    }
}

.top {
   left: 6%;
    font-size: 12px;
    display: block;
    cursor: pointer;
    position: absolute;

    bottom: 19%;
}

.imggg {
    width: 25%;
    // position: absolute;
    top: 102%;
    left: 5%;
    margin-top: 5px;
    font-size: 13px;
    cursor: pointer;
}

.tc {
    width: 200px;
    height: 230px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;



    img {
        width: 200px;
        height: 200px;
        // margin: auto;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* 设置为半透明的黑色 */
    z-index: 999;
    /* 确保遮罩层位于最顶层 */
}
</style>
