<template>
  <div id="app">
    <!-- :logoif=!$route.meta.logoif  -->
    <menu-Bar :flag="flag" v-if="$route.meta.isShowTop" />
    <div class="section" :style="{ height: $route.path == '/UserAgreement' ? '100%' : '' }">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <footercom v-if="!$route.meta.isFootercom" />
    <floatCard class="floatCard" />
  </div>
</template>

<script>
import menuBar from '@/components/menuBar'
import footercom from '@/components/footercom'
import floatCard from '@/components/floatCard'

export default {
  name: 'Home',
  components: {
    menuBar,
    footercom,
    floatCard
  },
  props: {},
  data() {
    return {
      flag: false
    }
  },
  computed: {},
  watch: {
    $route(newvalue, oldvalue) {
      // console.log(newvalue.path)
    }
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
 async mounted() {
    // 字典类型手机号
    const {data} = await this.getDicts('sys_account_number')
    let accountNumber = data.map(item=>item.dictValue)

    this.$store.dispatch('setAccountNumber',accountNumber)
    // 禁止右键
            window.oncontextmenu = function () { return false; }
     // 禁止f12
            window.onkeydown = window.onkeyup = window.onkeypress = function (event) {

              if (event.key == 'F12') {
                return false;
              }
            }


  },
  methods: {

  }
}
</script>

<style lang="scss">
#app {
  height: 100%;

}

.section {
  min-height: 800px;
  min-width: 1200px;
  margin: 0 auto;
}

.floatCard {
  position: fixed;
  right: -95px;
  top: 40%;
  z-index: 100;
}
</style>
