<template>

  <!--此组件只在【自主填报】和【智能填报】中使用，因html结构不同，所以【我的志愿表】中未使用。 -->
  <div class="tableCss" v-loading="loading">
    <!-- 志愿弹窗 -->
    <div v-if="type == 'ZZ'" class="drw " :class="{ 'rwdrw': zktf }">
      <div class="zk" @click="zkclick()">
        {{ zk }}
      </div>
      <el-empty description="请添加志愿" v-if="yzListrt.length == 0"></el-empty>
      <div class="drwit" v-else>
        <div class="drwItem" v-for="(item, index) in yzListrt">
          <div class="drwtitle">
            <div class="yx">
              <span>{{ index + 1 }}</span>
              {{ item.scorePlansEntity.collegeName }}
            </div>
            <span class="btn" @click="delzy(item)">删除</span>
          </div>
          <div class="cont">
            <span>{{ item.scorePlansEntity.collegeProvince }}
              | {{ item.scorePlansEntity.collegeGenre }}</span> <br>
            <span>专业代号: {{ item.scorePlansEntity.majorCode }}</span><br>
            <span>{{ item.scorePlansEntity.majorname }}</span>
          </div>
        </div>
      </div>

      <div class="btnzy" @click="handBc()">保存志愿( {{ yzListrt.length }})</div>
    </div>
    <!--    <p style="margin-bottom:10px;font-size:14px;">【提示】“新增专业”无历年录取参考数据，2023预测录取分数与实际可能存在误差，仅供参考，务必谨慎填报</p>-->
    <el-table :data="tableDataCopy" style="border-radius: 10px;border:1px solid #FAFAFA" :header-cell-style="{
    backgroundColor: '#FAFAFA',
    color: 'black',
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: '900'
  }" :cell-style="{
    textAlign: 'center',

  }">
      <el-table-column v-if="type == 'ZZ'" type="index" label="序号" :index="indexMethod" width="60">
      </el-table-column>
      <el-table-column type="index" v-if="type == 'ZN'" label="序号" width="60"></el-table-column>
      <el-table-column prop="" width="70" label="冲稳保">
        <template slot-scope="scope">
          <!-- <span style="font-size: 17px;">{{ scope.row.scorePlansEntity.admission }}</span> <span>%</span> -->
          <!-- <p v-show="scope.row.status == 1">风险极大</p> -->
          <p class="red ifrush" v-show="scope.row.status == 1">冲</p>
          <!-- <p v-show="scope.row.status == 2">风险中等</p> -->
          <p class="orange ifrush" v-show="scope.row.status == 2">稳</p>
          <!-- <p v-show="scope.row.status == 3">风险一般</p> -->
          <p class="green ifrush" v-show="scope.row.status == 3">保</p>

        </template>
      </el-table-column>
      <el-table-column prop="" width="80" label="学科评估">
        <template slot-scope="scope">
          <p
            v-if="scope.row.scorePlansEntity.grade == '_' || !scope.row.scorePlansEntity.grade || scope.row.scorePlansEntity.grade == '-'">
            ---
          </p>
          <p class="grade" v-else :style="{ backgroundColor: getGradeCss(scope.row.scorePlansEntity.grade) }">{{
    scope.row.scorePlansEntity.grade }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="招生专业">
        <template slot-scope="scope">
          <!-- 新增专业 -->
           <div class="schoolPlan" style="color: #12b098;margin-bottom: 0px;" v-if="scope.row.scorePlansEntity.typen == 1">2024新增专业</div>
          <!-- 专业名称 -->
          <span class="schoolPlan"  >{{scope.row.scorePlansEntity.majorname }}</span>

          <div class="f13">
            专业代号：{{ scope.row.scorePlansEntity.majorCode || '--' }}
          </div>
          <div class="f13">
           招生类型： {{ scope.row.scorePlansEntity.recruitStudentsCategory || '--'  }}
          </div>
          <p class="f13">
            <span style="display: inline;font-size:13px;">{{ !scope.row.scorePlansEntity.professionalInstruction ||
    scope.row.scorePlansEntity.professionalInstruction == 0 ? '' :
    scope.row.scorePlansEntity.professionalInstruction }}</span>
          </p>
          <!-- 只有智能显示专业的硕博点，有就显示（硕这个字，或者博这个字），没有就不显示 -->
          <p v-show="type == 'ZN'">
            <span class="sbText" v-if="scope.row.scorePlansEntity.ssd == '有'" style="background: #6cd7a1">硕</span>
            <span class="sbText" v-if="scope.row.scorePlansEntity.bsd == '有'" style="background: #99b2f1">博</span>
          </p>

        </template>
      </el-table-column>
      <el-table-column prop="" label="招生院校" width="170">
        <template slot-scope="scope">
          <p class="schoolName" @click="goCheckSchool(scope.row.scorePlansEntity)">
            {{ scope.row.scorePlansEntity.collegeName }}
            <el-popover placement="top-start" title="名企招聘" width="200" trigger="hover"
              :content="scope.row.famousRecruit">
              <img v-show="type == 'ZN'" slot="reference" style="width: 20px;vertical-align: text-top;"
                src="../assets/imgs/pin.png" alt="">
            </el-popover>
          </p>
           <p class="f13 txagn">
            院校代号：{{ scope.row.scorePlansEntity.collegeCode || '--' }}
          </p>
          <p class="f13 txagn">{{ scope.row.scorePlansEntity.collegeProvince }} | {{
    scope.row.collegeGenre }}</p>
          <!-- 本科批显示软科排名 -->
          <p class="f13 txagn" v-if="batchFile == '本科批'">软科排名：{{ scope.row.bcuRanking || '--' }}</p>
          <!-- 专科批显示GDI排名 -->
          <p class="f13 txagn" v-if="batchFile == '专科批'">GDI排名：{{ scope.row.gdiRanking || '--' }}</p>
          <p class="f13 txagn">
            {{ scope.row.scorePlansEntity.collegeLabel }}
          </p>
          <p class="f13 txagn">{{ scope.row.scorePlansEntity.collegeGenre }}</p>
          <p class="f13 txagn">硕/博点：{{ scope.row.degreePrograms }} / {{ scope.row.doctoralPrograms }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="" :label="(yearsList[2] || '') + '招生计划'" width="120">
        <template slot-scope="scope">
          <p>
            <span style="font-size: 17px;font-weight: bold;">{{ scope.row.scorePlansEntity.recruitStudentNumber
              }}</span>
            <span style="font-size: 12px;">人</span>
          </p>
          <p class="f13">
            <span>{{ scope.row.scorePlansEntity.professionalSystem }}年</span>
            &nbsp;
            <span>￥{{ scope.row.scorePlansEntity.tuition || '--' }}</span>
          </p>
          <p class="f13">
            <span>{{ scope.row.scorePlansEntity.subjects }}</span>
            +
            <span>{{ scope.row.scorePlansEntity.checkRequest }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="年份" width="80">
        <template slot-scope="scope">
          <div class="f13" style="height: 100%;">
            <!-- <p v-for="(item,index) in yearsList">{{ item}}</p> -->
            <p>{{ yearsList[2] }}</p>
            <p>{{ yearsList[1] }}</p>
            <p>{{ yearsList[0] }}</p>
          </div>
        </template>
      </el-table-column>
      <!-- 备注：前三年的录取人数，后台暂时没有返回字段。取得是分数，记得一定要修改。 -->
      <!-- <el-table-column label="录取人数">
              <template slot-scope="scope">
                <div class="f13">
                <p>{{ scope.row.yearbeforeScore || '---' }}人</p>
                  <p>{{ scope.row.lastyearScore || '---' }}人</p>
                  <p>{{ scope.row.scorePlansEntity.score || '---' }}人</p>
                  </div>
              </template>
        </el-table-column> -->
      <el-table-column label="最低分" width="70">
        <template slot-scope="scope">
          <div class="f13">

            <p>{{ scope.row.scorePlansEntity.score || '---' }}分</p>
            <p>{{ scope.row.lastyearScore || '---' }}分</p>
            <p>{{ scope.row.yearbeforeScore || '---' }}分</p>

          </div>
        </template>
      </el-table-column>
      <el-table-column label="最低位次" width="80">
        <template slot-scope="scope">
          <div class="f13">
            <p>{{ scope.row.scorePlansEntity.ranking || '---' }}名</p>

            <p>{{ scope.row.lastyearRanking || '---' }}名</p>
            <p>{{ scope.row.yearbeforeRanking || '---' }}名</p>

          </div>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column prop="" label="操作" v-if="type == 'ZZ'" width="90">
        <template slot-scope="scope">
          <!-- 拖动的icon -->
          <!-- <div>
              <i class="el-icon-rank fangxing"></i>
          </div> -->
          <div class="leadershipBtn" :class="{ activeLeadershipBtn: scope.row.isShow }" v-if="scope.row.isShow"
            @click="showleadBtn(scope.row, 1)">
            已填报
          </div>
          <div v-show="type == 'ZZ'" class="leadershipBtn" @click="showleadBtn(scope.row,2)" v-if="!scope.row.isShow">
            填报
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { REQvolunteerAdd, REQvolunteerAddZN, addUserScoreTables } from '@/api/aspiration.js'
import { numberDigit } from '@/utils/request.js'
import { getTime0623, getTime0802 } from '@/utils/time.js'
import Sortable from "sortablejs";

export default {
  name: 'intellDetails',
  mixins: [],
  components: {},
  props: ['tableData', 'loading', 'yearsList', 'type', 'pageNum','batchFile'],
  data() {
    return {

      zk: '展开志愿表',
      zktf: true,
      yzListrt: [],
      zkrds: true,
tableDataCopy:[]
    }
  },
  computed: {
    isYUCE() {
      return this.yearsList[2] == new Date(this.$store.state.serverTime).getFullYear()
    }
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
             this.resetShow(newVal)
      },
      immediate: true,
      deep:true
    }
  },
  created() { },
  mounted() {
    // this.$nextTick(()=>{
    //     this.drag()
    // })
  },
  methods: {
    resetShow(newVal){

      this.tableDataCopy = JSON.parse(JSON.stringify(newVal))
      if(this.tableDataCopy.length == 0){
        this.tableDataCopy = []
      }
         this.tableDataCopy.forEach((el, index) => {
              this.yzListrt.forEach((item, index) => {
                if (el.scorePlansEntity.spId == item.scorePlansEntity.spId) {
                  el.isShow = !el.isShow
                }
              })
            })
    },
    handBc() {
      if (this.yzListrt.length == 0) {
        this.$message.error("请选择志愿后提交")
        return
      }
      let arry = []
      this.yzListrt.forEach(item => {
        arry.push(item.scorePlansEntity.spId)
      })
      addUserScoreTables({
        phoneNumber: localStorage.getItem('phonenumber'),
        paramsType: 1,
        spIds: arry
        // numberId: this.numberId,
      }).then(
        res => {
          if (res.code == 200) {
            this.$message.success("保存成功")
            this.tableDataCopy.forEach((el, index) => {
              this.yzListrt.forEach((item, index) => {
                if (el.scorePlansEntity.spId == item.scorePlansEntity.spId) {
                  // 删除
                  el.isShow = !el.isShow
                }
              })
            })
            this.yzListrt = []
          }
        }
      )
    },
    zkclick() {
      this.zktf = !this.zktf
      if (this.zktf) {
        this.zk = '展开志愿表'
      } else {
        this.zk = '收起志愿表'
        this.zkrds = false
      }
    },
    drag() {
      const el = document.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false, // 拖拽不可用? false 启用
        ghostClass: '.fangxing', //拖拽样式
        animation: 150, // 拖拽延时，效果更好看
        onEnd: (e) => { // 拖拽结束时的触发
          console.log(e)
          return
          let arr = this.tableDataCopy; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理，获取最新的表格数据
          this.$nextTick(function () {
            this.tableDataCopy = arr;
            console.log(this.tableDataCopy)
          })
        },
      });
    },
    getGradeCss(grade) {
      if (grade.includes('A')) {
        return '#30C9B2'
      } else if (grade.includes('B')) {
        return '#3684E5'
      } else if (grade.includes('C')) {
        return '#FFB422'
      }
    },
    goCheckSchool(item) {
      this.$router.push({
        path: '/profile',
        query: {
          collegeNumber: item.collegeNumber
        }
      })
    },
    // 弹窗删除按钮
    delzy(item) {
      this.yzListrt.forEach((el, index) => {
        if (el.scorePlansEntity.spId == item.scorePlansEntity.spId) {
          // 删除
          this.yzListrt.splice(index, 1)
        }
      })
      this.tableDataCopy.forEach((el, index) => {
        if (el.scorePlansEntity.spId == item.scorePlansEntity.spId) {
          // 删除
          console.log(el.isShow);
          el.isShow = !el.isShow
        }
      })
    },
    showleadBtn(item, type) {
      if (this.zkrds) {
        this.zkrds = false
        this.zktf = false
        this.zk = '收起志愿表'
      }


      if (type == 2) {
        this.yzListrt.push(item)
      } else {
        this.yzListrt.forEach((el, index) => {
          if (el.scorePlansEntity.spId == item.scorePlansEntity.spId) {
            // 删除
            this.yzListrt.splice(index, 1)
          }
        })
      }


      item.isShow = !item.isShow

    },


    indexMethod(index) {
      if (this.pageNum) {
        return ((this.pageNum - 1) * 10 + index + 1)
      }
    },
    // 判断是否为高三学生 当前时间是否在6.20-8.15之间
    async isFillVolunteer() {
      const serverTime = new Date(this.$store.state.serverTime)
      const expirationTime = this.$store.state.userInfo.expirationTime
      if (!expirationTime) return false
      const endYear = expirationTime.split('-')[0]
      const yearFlag = +endYear === serverTime.getFullYear() // 是否高三
      const nowDate = serverTime.getTime()
      // const maxDate = new Date(`${new Date(nowDate).getFullYear()}-08-01 23:59:59`).getTime()
      // const minDate = new Date(`${new Date(nowDate).getFullYear()}-06-22 23:59:59`).getTime()
      const minDate = Date.parse(await this.getAlltime('4'))
      const maxDate = Date.parse(await this.getAlltime('5'))
      const dateFlag = nowDate < maxDate && nowDate > minDate // 当前日期是否填报期间
      return !yearFlag && dateFlag
    }

  }
}
</script>

<style scoped lang="scss">
.drw {
  width: 300px;
  height: 80vh;
  position: fixed;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  right: 0;
  top: 15%;
  z-index: 999;
  font-size: 20px;
  color: #ffffff;
  padding: 10px;

  .drwit {
    height: 90%;
    overflow-y: scroll;
  }

  .drwit::-webkit-scrollbar {
    width: 6px;
    /* 滚动条宽度 */
    height: 12px;
    /* 滚动条高度 */
  }

  .drwit::-webkit-scrollbar-track {
    background: #9aecdf;
    /* 滚动条轨道颜色 */
  }

  .drwit::-webkit-scrollbar-thumb {
    background: #12ae96;
    /* 滚动条拇指颜色 */
    border-radius: 6px;
    /* 滚动条拇指圆角 */
  }

  .drwit::-webkit-scrollbar-thumb:hover {
    background: #12ae96;
    /* 滚动条拇指悬停颜色 */
  }


  .btnzy {
    width: 280px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #12ae96;
    border-radius: 5px;
    position: absolute;
    bottom: 5px;
    cursor: pointer;


  }



  .zk {
    top: 0;
    width: 40px;
    height: 20vh;
    position: absolute;
    left: -40px;
    background: #12ae96;
    writing-mode: vertical-rl;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .drwItem {
    color: #000;
    font-size: 16px;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;

  }

  .drwtitle {
    display: flex;
    height: 60px;
    line-height: 60px;
  }

  .btn {
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: orangered;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    border-radius: 3px;
    margin: 20px 10px 0 10px;
  }

  .cont {
    width: 50%;
    font-size: 14px;
    color: #303030;
    text-align: center;

    span {
      font-size: 13px;
      color: #7c7c7c
    }
  }

  .yx {
    :nth-child(1) {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #12b098;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      border-radius: 3px
    }


  }
}

.rwdrw {
  right: -320px;

}

.tableCss {
  margin-top: 5px;
  width: 100%;
  position: relative;
}

.el-table span {
  // display: block;
  // margin: 5px 0px;
}

::v-deep .el-table .el-table__body {
  border-left: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.el-table .schoolName {
  font-weight: 800;
  font-size: 15px;
  cursor: pointer;
}

.el-table .f13 {
  font-size: 13px;
  color: #86909c;
}

.el-table .txagn {
  // text-align: left;
  // padding-left:15px;
}

.el-table .sbText {
  display: inline-block;
  font-size: 13px;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50%;
}

.el-table .ifrush {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: white;
  margin: 0 auto;
}

.el-table .red {
  background: red;
}

.el-table .orange {
  background: orange;
}

.el-table .green {
  background: green;
}

.el-table .status {
  color: white;
  right: 1px;
  top: -3px;
  position: absolute;
  border-radius: 50%;
  padding: 1px 3px;
}

.el-table .imgIcon {
  display: block;
  margin: 5px;
}

.el-table .schoolPlan {
  font-size: 15px;
  font-weight: 800;
  margin: 0px 0 10px 0px !important;
}

.el-table .grade {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  text-align: center;
  /* margin-left: auto; */
  line-height: 30px;
  margin: 0 auto;
}

.el-table .itemDiv {
  display: flex;
  position: absolute;
  background: white;
  left: -811px;
  height: 50px;
  z-index: 102;
  border-radius: 10px;
  border: 1px solid #ccc;

  /* div:hover{
        color:#30C9B2;
      } */
  div {
    width: 90px;
    cursor: pointer;
    height: 50px;
    border-right: 1px solid #ccc;
    text-align: center;

    p:nth-of-type(1) {
      border-bottom: 1px solid #ccc;
    }

    p:nth-of-type(2):hover {
      color: #30c9b2;
    }
  }
}

::v-deep .el-table .el-table__body-wrapper,
.el-table {
  overflow: initial;
}

::v-deep .el-table .el-table__row {
  height: 135px;
}

.el-table .iconCss p {
  cursor: pointer;
}

.el-table .leadershipBtn {
  width: 62px;
  height: 33px;
  background: #ffffff;
  border: 3px solid #12b098;
  border-radius: 17px;
  line-height: 28px;
  text-align: center;
  color: #12b098;
  cursor: pointer;
}

.el-table .activeLeadershipBtn {
  background: #12b098;
  color: white;
}
</style>
