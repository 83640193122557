import request from '@/utils/request.js'
// 获取学科门类数组
export const subjectCategories = (query) => {
  return request({
    method: 'get',
    url: '/system/subjectCategories/list',
    params: query
  })
}
// 根据专业代码查询专业详情  /dev/api/system/professionalMajor/queryByProfessionalCode
export const queryByProfessionalCode = (query) => {
  return request({
    method: 'get',
    url: '/system/professionalMajor/queryByProfessionalCode',
    params: query
  })
}
// 根据本科或者专科查询学科门类
export const queryLevelAll = (query) => {
  return request({
    method: 'get',
    url: '/system/subjectCategories/queryLevelAll',
    params: query
  })
}

// 查询专业名称
export const queryLevelVo = (query) => {
  return request({
    method: 'get',
    url: '/system/subjectCategories/queryLevelVo',
    params: query
  })
}

// 查询专业
export const queryByProfessionalMajor = (query) => {
  let url = query.professionalName ? '/system/professionalMajor/queryByProfessionalMajor' : '/system/professionalMajor/queryByProfessionalAll'
  return request({
    method: 'get',
    url,
    params: query
  })
}
// 查询职业第一类+第二类【】
export const queryJobName = (query) => {
  return request({
    method: 'get',
    url: '/system/career/queryCareerTree',
    params: query
  })
}
// 查询职业详情 /system/career/queryCareerDetail
export const queryCareerDetail = (query) => {
  return request({
    method: 'get',
    url: '/system/career/queryCareerDetail',
    params: query
  })
}
// 搜索职业
export const queryCareer = (query) => {
  return request({
    method: 'get',
    url: '/system/career/queryCareer',
    params: query
  })
}
// 就业方向数据
export const queryJobNameTB = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/intelligentResult/getjyfxtalbe',
    params: query
  })
}



export function getVolunteerResultPamas(data) {
  return request({
    method: 'post',
    url: '/system/volunteerResult/getVolunteerResultPamas',
    data: data
  })
}

export function setVolunteerResultPamas(data) {
  return request({
    method: 'post',
    url: '/system/volunteerResult/setVolunteerResultPamas',
    data: data
  })
}