<template>

  <div class="footercom">
    <div class="minw">

      <div class="btm">
        <div class="left">
          <span style="">
            友情链接
            <span style="display: inline-block;margin-left: 10px;">
                <a href="http://www.hebeea.edu.cn/" target="_blank">河北省教育考试院</a>
              <a href="https://gaokao.chsi.com.cn/" target="_blank">阳光高考网</a>
              <a href="https://www.hebpta.com.cn/" target="_blank">河北省人事考试网</a>
              <a href="http://jyt.hebei.gov.cn/" target="_blank">河北省教育厅</a>
              <a href="https://yz.chsi.com.cn/" target="_blank">中国研究生招生信息网</a>
            </span>
             <span style="display: inline-block;margin :10px 0px 0 75px;">
              <a href="https://www.chsi.com.cn/" target="_blank">中国高等教育学生信息网</a>
               <a href="https://cet.neea.edu.cn/" target="_blank">全国大学英语四六级考试</a>
              <a href="https://www.cscse.edu.cn/" target="_blank">中国留学网</a>
              <a href="https://www.fortunechina.com/fortune500/node_65.htm" style="margin-left: 20px;" target="_blank">财富中文网—世界500强</a>
            </span>
          </span>
          <span style="display: inline-block;margin-top: 10px;">
            官方声明
            <span style="display: inline-block;margin-left: 10px;">
              <router-link target="_blank" :to="{ path: '/UserAgreement', query: { type: 1 } }">
                隐私协议
              </router-link>
              <router-link target="_blank" :to="{ path: '/UserAgreement', query: { type: 2 } }">
                用户协议
              </router-link>
            </span>
          </span>
          <span style="display: inline-block;margin-top: 10px;">
            备案号&nbsp;&nbsp;&nbsp;
            <span style="display: inline-block;margin-left: 10px;">
              <a href="https://beian.miit.gov.cn" target="_blank">冀ICP备2023005371号-1</a>
            </span>
          </span>
        </div>
        <div class="right">
          <div class="rgimg">
            <div>
              <img class="GZH" src="../assets/imgs/GZH.jpg" alt="">
              <div style="text-align: center;">关注公众号</div>
            </div>
            <div>
              <img class="GZH" style="height:82px;" src="../assets/imgs/xcx.jpg" alt="">
              <div style="text-align: center;">微信小程序</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'footercom',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.footercom {
  box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 0 10px;

  margin: 10px auto 0;
  background: #434040;
  color: white;

  .btm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      span a {
        display: inline-block;
        margin-right: 30px;
        line-height: 25px;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;

      .rgimg {
        width: 195px;
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: column;
        }

        .GZH {
          width: 85px;
        }
      }

      .rigbox {
        margin-left: 25px;
      }
    }
  }
  a {
    color: white;
  }
}
</style>
