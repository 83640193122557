import request from '@/utils/request.js'
import axios from 'axios'

/*
=======================================================自主填报========================================
*/
// 自主填报获取院校分页列表
export const REQvolunteer = (query) => {
  return request({
    method: 'post',
    // url: '/system/volunteer/queryByparams',
    url: '/system/volunteerResult/getVolunteerResult',
    data: query
  })
}
//  自主填报获取办学类型 /dev/api
export const REQcollegeGenre = (query) => {
  return request({
    method: 'get',
    url: '/system/scoreplan/querycollegeGenre',
    params: query
  })
}
// 自主填报 填志愿  /dev/api/system/volunteer/add
export const REQvolunteerAdd = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/add',
    params: query
  })
}
// 查询志愿表  /dev/api/system/volunteer/list
export const REQvolunteerList = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/list',
    params: query
  })
}
// 志愿表删除  /dev/api
export const REQvolunteerDel = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/delete',
    params: query
  })
}
// 志愿换位  /dev/api
export const REQvolunteercheckspace = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/checkspace',
    params: query
  })
}
/*
=======================================================智能填报========================================
*/
// 获取智能填报根据手机号  /dev/api
export const REQvolunteerListZN = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/intelligent/list',
    params: query
  })
}
// 智能填报获取院校列表
export const REQvolunteerZN = (query) => {
  return request({
    method: 'post',
    // url: '/system/volunteer/intelligent/queryByIntelligentVolunteerOptimize',1
    // url: '/system/volunteer/intelligent/getIntelligentResult',2
    url: '/system/volunteer/intelligentResult/getIntelligentResult',

    data: query
  })
}

// 智能填报填志愿
export const REQvolunteerAddZN = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/intelligent/add',
    params: query
  })
}
// 智能填报志愿表删除
export const REQvolunteerDelZN = (query) => {
  return request({
    method: 'get',
    url: '/system/userscoreplanstable/removeDetailList',
    // url: '/system/volunteer/intelligent/delete', //【原接口】
    params: query
  })
}
// 智能填报志愿换位  /dev/api
export const REQvolunteercheckspaceZN = (query) => {
  return request({
    method: 'get',
    url: '/system/userscoreplanstable/chargeNum',
    params: query
  })
}
// 获取志愿分数线
export const REQscore = (query) => {
  return request({
    method: 'get',
    url: '/system/line/queryByScoreLine',
    params: query
  })
}
// 根据分数获取排名 /system/levelscore/querylevel
export const REQRank = (query) => {
  return request({
    method: 'get',
    url: '/system/levelscore/querylevel',
    params: query
  })
}

// 获取导出志愿表的名称，名称用来请求resourceFileName这个接口
export const REQdownloadvolunteer = (url) => {
  return request({
    method: 'get',
    url: url
  })
}
// 根据表名称导出志愿表
export const resourceFileName = (query) => {
  return request({
    method: 'get',
    url: '/common/download/resourceFileName',
    params: query,
    responseType: 'blob'
  })
}
// 智能填报导出  /system/volunteer/intelligentResult/download?userId=&volunteerNumbers
export const downloadZNTableEXcel = (query) => {
  return request({
    method: 'get',
    url: '/system/volunteer/intelligentResult/download',
    params: query,
  })
}

// export const addUserScoreTable = (query) => {
//   return request({
//     method: 'post',
//     url: '/system/userscoreplanstable/addUserScoreTable',
//     data: query,

//   })
// }

// 保存志愿

export const addUserScoreTable = (query) => {
  return request({
    method: 'post',
    url: '/system/userscoreplanstable/addUserScoreTable',
    data: query
  })
}
// 查看是否有最近的智能填报结果
export const checkZYLB = (query) => {
  return request({
    method: 'post',
    url: '/system/volunteer/intelligent/checkZYLB',
    data: query
  })
}


export const addUserScoreTables = (query) => {
  return request({
    method: 'post',
    url: '/system/userscoreplanstable/addUserScoreTables',
    data: query
  })
}

