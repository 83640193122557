import request from '@/utils/request.js'
import axios from 'axios'

export const REQZFBPAY = (obj) => {
  return request({
    method: 'post',
    url: '/system/alipay/trade',
    data: obj
  })
}
// 根据手机号查询所有订单
export const REQqueryByPhoneNumber = (obj) => {
  return request({
    method: 'get',
    url: '/system/alipay/queryByPhoneNumber',
    params: obj
  })
}
// 根据手机号+订单状态查询订单
export const getUserOrder = (obj) => {
  return request({
    method: 'get',
    url: '/system/alipay/getUserOrder',
    params: obj
  })
}
//  取消订单 /closeTrade
export const REQcloseTrade = (obj) => {
  return request({
    method: 'post',
    url: '/system/alipay/closeTrade',
    data: obj
  })
}
// 退款  refund
export const REQrefund = (obj) => {
  return request({
    method: 'post',
    url: '/system/alipay/refund',
    data: obj
  })
}
// /system/userOrder/addFreeRecharge  免费会员
export const REQZFBPAYFree = (obj) => {
  return request({
    method: 'post',
    url: '/system/userOrder/addFreeRecharge',
    data: obj
  })
}
// 微信支付
export function REQWXPAY(obj) {
  return request({
    method: 'post',
    url: '/system/pcwxlipay/trade',
    data: obj
  })
}
// 查询微信订单状态  tradeQuery
export function tradeQuery(obj) {
  return request({
    method: 'post',
    url: '/system/pcwxlipay/tradeQuery',
    data: obj
  })
}
//  判断激活码是否体验卡
export function checkVIP(data) {
  return request({
    method: 'post',
    url: '/system/activationCode/checkVIP',
    data
  })
}
// 激活码激活vip
export function activateCode(data) {
  return request({
    method: 'post',
    url: '/system/activationCode/activateVIP',
    data
  })
}
// 查询支付宝订单状态
export function tradeQueryZFB(obj) {
  return request({
    method: 'post',
    url: '/system/alipay/tradeQuery',
    data: obj
  })
}
