 export let provienceAndcityArea = [
  {
  "name": "北京",
  "id": 1,
  "child": [{
    "name": "北京",
    "id": 1
  }, ]
}, {
  "name": "天津",
  "id": 2,
  "child": [{
    "name": "天津",
    "id": 19
  }, ]
}, {
  "name": "河北",
  "id": 3,
  "child": [{
    "name": "石家庄",
    "id": 37
  }, {
    "name": "唐山",
    "id": 38
  }, {
    "name": "秦皇岛",
    "id": 39
  }, {
    "name": "邯郸",
    "id": 40
  }, {
    "name": "邢台",
    "id": 41
  }, {
    "name": "保定",
    "id": 42
  }, {
    "name": "张家口",
    "id": 43
  }, {
    "name": "承德",
    "id": 44
  }, {
    "name": "沧州",
    "id": 45
  }, {
    "name": "廊坊",
    "id": 46
  }, {
    "name": "衡水",
    "id": 47
  }]
}, {
  "name": "山西",
  "id": 4,
  "child": [{
    "name": "太原",
    "id": 48
  }, {
    "name": "大同",
    "id": 49
  }, {
    "name": "阳泉",
    "id": 50
  }, {
    "name": "长治",
    "id": 51
  }, {
    "name": "晋城",
    "id": 52
  }, {
    "name": "朔州",
    "id": 53
  }, {
    "name": "忻州",
    "id": 54
  }, {
    "name": "吕梁",
    "id": 55
  }, {
    "name": "晋中",
    "id": 56
  }, {
    "name": "临汾",
    "id": 57
  }, {
    "name": "运城",
    "id": 58
  }]
}, {
  "name": "内蒙古",
  "id": 5,
  "child": [{
    "name": "呼和浩特",
    "id": 59
  }, {
    "name": "包头",
    "id": 60
  }, {
    "name": "乌海",
    "id": 61
  }, {
    "name": "赤峰",
    "id": 62
  }, {
    "name": "呼伦贝尔",
    "id": 63
  }, {
    "name": "兴安盟",
    "id": 64
  }, {
    "name": "通辽",
    "id": 65
  }, {
    "name": "锡林郭勒盟",
    "id": 66
  }, {
    "name": "乌兰察布盟",
    "id": 67
  }, {
    "name": "伊克昭盟",
    "id": 68
  }, {
    "name": "巴彦淖尔盟",
    "id": 69
  }, {
    "name": "阿拉善盟",
    "id": 70
  }]
}, {
  "name": "辽宁",
  "id": 6,
  "child": [{
    "name": "沈阳",
    "id": 71
  }, {
    "name": "大连",
    "id": 72
  }, {
    "name": "鞍山",
    "id": 73
  }, {
    "name": "抚顺",
    "id": 74
  }, {
    "name": "本溪",
    "id": 75
  }, {
    "name": "丹东",
    "id": 76
  }, {
    "name": "锦州",
    "id": 77
  }, {
    "name": "营口",
    "id": 78
  }, {
    "name": "阜新",
    "id": 79
  }, {
    "name": "辽阳",
    "id": 80
  }, {
    "name": "盘锦",
    "id": 81
  }, {
    "name": "铁岭",
    "id": 82
  }, {
    "name": "朝阳",
    "id": 83
  }, {
    "name": "葫芦岛",
    "id": 84
  }, {
    "name": "其他",
    "id": 85
  }]
}, {
  "name": "吉林",
  "id": 7,
  "child": [{
    "name": "长春",
    "id": 86
  }, {
    "name": "吉林",
    "id": 87
  }, {
    "name": "四平",
    "id": 88
  }, {
    "name": "辽源",
    "id": 89
  }, {
    "name": "通化",
    "id": 90
  }, {
    "name": "白山",
    "id": 91
  }, {
    "name": "松原",
    "id": 92
  }, {
    "name": "白城",
    "id": 93
  }, {
    "name": "延边朝鲜族自治州",
    "id": 94
  }, {
    "name": "其他",
    "id": 95
  }]
}, {
  "name": "黑龙江",
  "id": 8,
  "child": [{
    "name": "哈尔滨",
    "id": 96
  }, {
    "name": "齐齐哈尔",
    "id": 97
  }, {
    "name": "鹤岗",
    "id": 98
  }, {
    "name": "双鸭山",
    "id": 99
  }, {
    "name": "鸡西",
    "id": 100
  }, {
    "name": "大庆",
    "id": 101
  }, {
    "name": "伊春",
    "id": 102
  }, {
    "name": "牡丹江",
    "id": 103
  }, {
    "name": "佳木斯",
    "id": 104
  }, {
    "name": "七台河",
    "id": 105
  }, {
    "name": "黑河",
    "id": 106
  }, {
    "name": "绥化",
    "id": 107
  }, {
    "name": "大兴安岭地区",
    "id": 108
  }, {
    "name": "其他",
    "id": 109
  }]
}, {
  "name": "上海",
  "id": 9,
  "child": [{
    "name": "上海",
    "id": 110
  } ]
}, {
  "name": "江苏",
  "id": 10,
  "child": [{
    "name": "南京",
    "id": 130
  }, {
    "name": "苏州",
    "id": 131
  }, {
    "name": "无锡",
    "id": 132
  }, {
    "name": "常州",
    "id": 133
  }, {
    "name": "镇江",
    "id": 134
  }, {
    "name": "南通",
    "id": 135
  }, {
    "name": "泰州",
    "id": 136
  }, {
    "name": "扬州",
    "id": 137
  }, {
    "name": "盐城",
    "id": 138
  }, {
    "name": "连云港",
    "id": 139
  }, {
    "name": "徐州",
    "id": 140
  }, {
    "name": "淮安",
    "id": 141
  }, {
    "name": "宿迁",
    "id": 142
  },  ]
}, {
  "name": "浙江",
  "id": 11,
  "child": [{
    "name": "杭州",
    "id": 144
  }, {
    "name": "宁波",
    "id": 145
  }, {
    "name": "温州",
    "id": 146
  }, {
    "name": "嘉兴",
    "id": 147
  }, {
    "name": "湖州",
    "id": 148
  }, {
    "name": "绍兴",
    "id": 149
  }, {
    "name": "金华",
    "id": 150
  }, {
    "name": "衢州",
    "id": 151
  }, {
    "name": "舟山",
    "id": 152
  }, {
    "name": "台州",
    "id": 153
  }, {
    "name": "丽水",
    "id": 154
  }, {
    "name": "其他",
    "id": 155
  }]
}, {
  "name": "安徽",
  "id": 12,
  "child": [{
    "name": "合肥",
    "id": 156
  }, {
    "name": "芜湖",
    "id": 157
  }, {
    "name": "蚌埠",
    "id": 158
  }, {
    "name": "淮南",
    "id": 159
  }, {
    "name": "马鞍山",
    "id": 160
  }, {
    "name": "淮北",
    "id": 161
  }, {
    "name": "铜陵",
    "id": 162
  }, {
    "name": "安庆",
    "id": 163
  }, {
    "name": "黄山",
    "id": 164
  }, {
    "name": "滁州",
    "id": 165
  }, {
    "name": "阜阳",
    "id": 166
  }, {
    "name": "宿州",
    "id": 167
  }, {
    "name": "巢湖",
    "id": 168
  }, {
    "name": "六安",
    "id": 169
  }, {
    "name": "亳州",
    "id": 170
  }, {
    "name": "池州",
    "id": 171
  }, {
    "name": "宣城",
    "id": 172
  }, {
    "name": "其他",
    "id": 173
  }]
}, {
  "name": "福建",
  "id": 13,
  "child": [{
    "name": "福州",
    "id": 174
  }, {
    "name": "厦门",
    "id": 175
  }, {
    "name": "莆田",
    "id": 176
  }, {
    "name": "三明",
    "id": 177
  }, {
    "name": "泉州",
    "id": 178
  }, {
    "name": "漳州",
    "id": 179
  }, {
    "name": "南平",
    "id": 180
  }, {
    "name": "龙岩",
    "id": 181
  }, {
    "name": "宁德",
    "id": 182
  }, {
    "name": "其他",
    "id": 183
  }]
}, {
  "name": "江西",
  "id": 14,
  "child": [{
    "name": "南昌",
    "id": 184
  }, {
    "name": "景德镇",
    "id": 185
  }, {
    "name": "萍乡",
    "id": 186
  }, {
    "name": "九江",
    "id": 187
  }, {
    "name": "新余",
    "id": 188
  }, {
    "name": "鹰潭",
    "id": 189
  }, {
    "name": "赣州",
    "id": 190
  }, {
    "name": "吉安",
    "id": 191
  }, {
    "name": "宜春",
    "id": 192
  }, {
    "name": "抚州",
    "id": 193
  }, {
    "name": "上饶",
    "id": 194
  }, {
    "name": "其他",
    "id": 195
  }]
}, {
  "name": "山东",
  "id": 15,
  "child": [{
    "name": "济南",
    "id": 196
  }, {
    "name": "青岛",
    "id": 197
  }, {
    "name": "淄博",
    "id": 198
  }, {
    "name": "枣庄",
    "id": 199
  }, {
    "name": "东营",
    "id": 200
  }, {
    "name": "烟台",
    "id": 201
  }, {
    "name": "潍坊",
    "id": 202
  }, {
    "name": "济宁",
    "id": 203
  }, {
    "name": "泰安",
    "id": 204
  }, {
    "name": "威海",
    "id": 205
  }, {
    "name": "日照",
    "id": 206
  }, {
    "name": "莱芜",
    "id": 207
  }, {
    "name": "临沂",
    "id": 208
  }, {
    "name": "德州",
    "id": 209
  }, {
    "name": "聊城",
    "id": 210
  }, {
    "name": "滨州",
    "id": 211
  }, {
    "name": "菏泽",
    "id": 212
  }, {
    "name": "其他",
    "id": 213
  }]
}, {
  "name": "河南",
  "id": 16,
  "child": [{
    "name": "郑州",
    "id": 214
  }, {
    "name": "开封",
    "id": 215
  }, {
    "name": "洛阳",
    "id": 216
  }, {
    "name": "平顶山",
    "id": 217
  }, {
    "name": "安阳",
    "id": 218
  }, {
    "name": "鹤壁",
    "id": 219
  }, {
    "name": "新乡",
    "id": 220
  }, {
    "name": "焦作",
    "id": 221
  }, {
    "name": "濮阳",
    "id": 222
  }, {
    "name": "许昌",
    "id": 223
  }, {
    "name": "漯河",
    "id": 224
  }, {
    "name": "三门峡",
    "id": 225
  }, {
    "name": "南阳",
    "id": 226
  }, {
    "name": "商丘",
    "id": 227
  }, {
    "name": "信阳",
    "id": 228
  }, {
    "name": "周口",
    "id": 229
  }, {
    "name": "驻马店",
    "id": 230
  }, {
    "name": "焦作",
    "id": 231
  }, {
    "name": "其他",
    "id": 232
  }]
}, {
  "name": "湖北",
  "id": 17,
  "child": [{
    "name": "武汉",
    "id": 233
  }, {
    "name": "黄石",
    "id": 234
  }, {
    "name": "十堰",
    "id": 235
  }, {
    "name": "荆州",
    "id": 236
  }, {
    "name": "宜昌",
    "id": 237
  }, {
    "name": "襄樊",
    "id": 238
  }, {
    "name": "鄂州",
    "id": 239
  }, {
    "name": "荆门",
    "id": 240
  }, {
    "name": "孝感",
    "id": 241
  }, {
    "name": "黄冈",
    "id": 242
  }, {
    "name": "咸宁",
    "id": 243
  }, {
    "name": "随州",
    "id": 244
  }, {
    "name": "恩施土家族苗族自治州",
    "id": 245
  }, {
    "name": "仙桃",
    "id": 246
  }, {
    "name": "天门",
    "id": 247
  }, {
    "name": "潜江",
    "id": 248
  }, {
    "name": "神农架林区",
    "id": 249
  }, {
    "name": "其他",
    "id": 250
  }]
}, {
  "name": "湖南",
  "id": 18,
  "child": [{
    "name": "长沙",
    "id": 251
  }, {
    "name": "株洲",
    "id": 252
  }, {
    "name": "湘潭",
    "id": 253
  }, {
    "name": "衡阳",
    "id": 254
  }, {
    "name": "邵阳",
    "id": 255
  }, {
    "name": "岳阳",
    "id": 256
  }, {
    "name": "常德",
    "id": 257
  }, {
    "name": "张家界",
    "id": 258
  }, {
    "name": "益阳",
    "id": 259
  }, {
    "name": "郴州",
    "id": 260
  }, {
    "name": "永州",
    "id": 261
  }, {
    "name": "怀化",
    "id": 262
  }, {
    "name": "娄底",
    "id": 263
  }, {
    "name": "湘西土家族苗族自治州",
    "id": 264
  }, {
    "name": "其他",
    "id": 265
  }]
}, {
  "name": "广东",
  "id": 19,
  "child": [{
    "name": "广州",
    "id": 266
  }, {
    "name": "深圳",
    "id": 267
  }, {
    "name": "东莞",
    "id": 268
  }, {
    "name": "中山",
    "id": 269
  }, {
    "name": "潮州",
    "id": 270
  }, {
    "name": "揭阳",
    "id": 271
  }, {
    "name": "云浮",
    "id": 272
  }, {
    "name": "珠海",
    "id": 273
  }, {
    "name": "汕头",
    "id": 274
  }, {
    "name": "韶关",
    "id": 275
  }, {
    "name": "佛山",
    "id": 276
  }, {
    "name": "江门",
    "id": 277
  }, {
    "name": "湛江",
    "id": 278
  }, {
    "name": "茂名",
    "id": 279
  }, {
    "name": "肇庆",
    "id": 280
  }, {
    "name": "惠州",
    "id": 281
  }, {
    "name": "梅州",
    "id": 282
  }, {
    "name": "汕尾",
    "id": 283
  }, {
    "name": "河源",
    "id": 284
  }, {
    "name": "阳江",
    "id": 285
  }, {
    "name": "清远",
    "id": 286
  }]
}, {
  "name": "广西",
  "id": 20,
  "child": [{
    "name": "南宁",
    "id": 287
  }, {
    "name": "柳州",
    "id": 288
  }, {
    "name": "桂林",
    "id": 289
  }, {
    "name": "梧州",
    "id": 290
  }, {
    "name": "北海",
    "id": 291
  }, {
    "name": "防城港",
    "id": 292
  }, {
    "name": "钦州",
    "id": 293
  }, {
    "name": "贵港",
    "id": 294
  }, {
    "name": "玉林",
    "id": 295
  }, {
    "name": "百色",
    "id": 296
  }, {
    "name": "贺州",
    "id": 297
  }, {
    "name": "河池",
    "id": 298
  }, {
    "name": "来宾",
    "id": 299
  }, {
    "name": "崇左",
    "id": 300
  }, {
    "name": "其他",
    "id": 301
  }]
}, {
  "name": "海南",
  "id": 21,
  "child": [{
    "name": "海口",
    "id": 302
  }, {
    "name": "三亚",
    "id": 303
  }, {
    "name": "五指山",
    "id": 304
  }, {
    "name": "琼海",
    "id": 305
  }, {
    "name": "儋州",
    "id": 306
  }, {
    "name": "文昌",
    "id": 307
  }, {
    "name": "万宁",
    "id": 308
  }, {
    "name": "东方",
    "id": 309
  }, {
    "name": "澄迈县",
    "id": 310
  }, {
    "name": "定安县",
    "id": 311
  }, {
    "name": "屯昌县",
    "id": 312
  }, {
    "name": "临高县",
    "id": 313
  }, {
    "name": "白沙黎族自治县",
    "id": 314
  }, {
    "name": "昌江黎族自治县",
    "id": 315
  }, {
    "name": "乐东黎族自治县",
    "id": 316
  }, {
    "name": "陵水黎族自治县",
    "id": 317
  }, {
    "name": "保亭黎族苗族自治县",
    "id": 318
  }, {
    "name": "琼中黎族苗族自治县",
    "id": 319
  }, {
    "name": "其他",
    "id": 320
  }]
}, {
  "name": "重庆",
  "id": 22,
  "child": [{
    "name": "重庆",
    "id": 321
  }]
}, {
  "name": "四川",
  "id": 23,
  "child": [{
    "name": "成都",
    "id": 362
  }, {
    "name": "自贡",
    "id": 363
  }, {
    "name": "攀枝花",
    "id": 364
  }, {
    "name": "泸州",
    "id": 365
  }, {
    "name": "德阳",
    "id": 366
  }, {
    "name": "绵阳",
    "id": 367
  }, {
    "name": "广元",
    "id": 368
  }, {
    "name": "遂宁",
    "id": 369
  }, {
    "name": "内江",
    "id": 370
  }, {
    "name": "乐山",
    "id": 371
  }, {
    "name": "南充",
    "id": 372
  }, {
    "name": "眉山",
    "id": 373
  }, {
    "name": "宜宾",
    "id": 374
  }, {
    "name": "广安",
    "id": 375
  }, {
    "name": "达州",
    "id": 376
  }, {
    "name": "雅安",
    "id": 377
  }, {
    "name": "巴中",
    "id": 378
  }, {
    "name": "资阳",
    "id": 379
  }, {
    "name": "阿坝藏族羌族自治州",
    "id": 380
  }, {
    "name": "甘孜藏族自治州",
    "id": 381
  }, {
    "name": "凉山彝族自治州",
    "id": 382
  }, {
    "name": "其他",
    "id": 383
  }]
}, {
  "name": "贵州",
  "id": 24,
  "child": [{
    "name": "贵阳",
    "id": 384
  }, {
    "name": "六盘水",
    "id": 385
  }, {
    "name": "遵义",
    "id": 386
  }, {
    "name": "安顺",
    "id": 387
  }, {
    "name": "铜仁地区",
    "id": 388
  }, {
    "name": "毕节地区",
    "id": 389
  }, {
    "name": "黔西南布依族苗族自治州",
    "id": 390
  }, {
    "name": "黔东南苗族侗族自治州",
    "id": 391
  }, {
    "name": "黔南布依族苗族自治州",
    "id": 392
  }, {
    "name": "其他",
    "id": 393
  }]
}, {
  "name": "云南",
  "id": 25,
  "child": [{
    "name": "昆明",
    "id": 394
  }, {
    "name": "曲靖",
    "id": 395
  }, {
    "name": "玉溪",
    "id": 396
  }, {
    "name": "保山",
    "id": 397
  }, {
    "name": "昭通",
    "id": 398
  }, {
    "name": "丽江",
    "id": 399
  }, {
    "name": "普洱",
    "id": 400
  }, {
    "name": "临沧",
    "id": 401
  }, {
    "name": "德宏傣族景颇族自治州",
    "id": 402
  }, {
    "name": "怒江傈僳族自治州",
    "id": 403
  }, {
    "name": "迪庆藏族自治州",
    "id": 404
  }, {
    "name": "大理白族自治州",
    "id": 405
  }, {
    "name": "楚雄彝族自治州",
    "id": 406
  }, {
    "name": "红河哈尼族彝族自治州",
    "id": 407
  }, {
    "name": "文山壮族苗族自治州",
    "id": 408
  }, {
    "name": "西双版纳傣族自治州",
    "id": 409
  }, {
    "name": "其他",
    "id": 410
  }]
}, {
  "name": "西藏",
  "id": 26,
  "child": [{
    "name": "拉萨",
    "id": 411
  }, {
    "name": "那曲地区",
    "id": 412
  }, {
    "name": "昌都地区",
    "id": 413
  }, {
    "name": "林芝地区",
    "id": 414
  }, {
    "name": "山南地区",
    "id": 415
  }, {
    "name": "日喀则地区",
    "id": 416
  }, {
    "name": "阿里地区",
    "id": 417
  }, {
    "name": "其他",
    "id": 418
  }]
}, {
  "name": "陕西",
  "id": 27,
  "child": [{
    "name": "西安",
    "id": 419
  }, {
    "name": "铜川",
    "id": 420
  }, {
    "name": "宝鸡",
    "id": 421
  }, {
    "name": "咸阳",
    "id": 422
  }, {
    "name": "渭南",
    "id": 423
  }, {
    "name": "延安",
    "id": 424
  }, {
    "name": "汉中",
    "id": 425
  }, {
    "name": "榆林",
    "id": 426
  }, {
    "name": "安康",
    "id": 427
  }, {
    "name": "商洛",
    "id": 428
  }, {
    "name": "其他",
    "id": 429
  }]
}, {
  "name": "甘肃",
  "id": 28,
  "child": [{
    "name": "兰州",
    "id": 430
  }, {
    "name": "嘉峪关",
    "id": 431
  }, {
    "name": "金昌",
    "id": 432
  }, {
    "name": "白银",
    "id": 433
  }, {
    "name": "天水",
    "id": 434
  }, {
    "name": "武威",
    "id": 435
  }, {
    "name": "酒泉",
    "id": 436
  }, {
    "name": "张掖",
    "id": 437
  }, {
    "name": "庆阳",
    "id": 438
  }, {
    "name": "平凉",
    "id": 439
  }, {
    "name": "定西",
    "id": 440
  }, {
    "name": "陇南",
    "id": 441
  }, {
    "name": "临夏回族自治州",
    "id": 442
  }, {
    "name": "甘南藏族自治州",
    "id": 443
  }, {
    "name": "其他",
    "id": 444
  }]
}, {
  "name": "青海",
  "id": 29,
  "child": [{
    "name": "西宁",
    "id": 445
  }, {
    "name": "海东地区",
    "id": 446
  }, {
    "name": "海北藏族自治州",
    "id": 447
  }, {
    "name": "海南藏族自治州",
    "id": 448
  }, {
    "name": "黄南藏族自治州",
    "id": 449
  }, {
    "name": "果洛藏族自治州",
    "id": 450
  }, {
    "name": "玉树藏族自治州",
    "id": 451
  }, {
    "name": "海西蒙古族藏族自治州",
    "id": 452
  }, {
    "name": "其他",
    "id": 453
  }]
}, {
  "name": "宁夏",
  "id": 30,
  "child": [{
    "name": "银川",
    "id": 454
  }, {
    "name": "石嘴山",
    "id": 455
  }, {
    "name": "吴忠",
    "id": 456
  }, {
    "name": "固原",
    "id": 457
  }, {
    "name": "中卫",
    "id": 458
  }, {
    "name": "其他",
    "id": 459
  }]
}, {
  "name": "新疆",
  "id": 31,
  "child": [{
    "name": "乌鲁木齐",
    "id": 460
  }, {
    "name": "克拉玛依",
    "id": 461
  }, {
    "name": "吐鲁番地区",
    "id": 462
  }, {
    "name": "哈密地区",
    "id": 463
  }, {
    "name": "和田地区",
    "id": 464
  }, {
    "name": "阿克苏地区",
    "id": 465
  }, {
    "name": "喀什地区",
    "id": 466
  }, {
    "name": "克孜勒苏柯尔克孜自治州",
    "id": 467
  }, {
    "name": "巴音郭楞蒙古自治州",
    "id": 468
  }, {
    "name": "昌吉回族自治州",
    "id": 469
  }, {
    "name": "博尔塔拉蒙古自治州",
    "id": 470
  }, {
    "name": "石河子",
    "id": 471
  }, {
    "name": "阿拉尔",
    "id": 472
  }, {
    "name": "图木舒克",
    "id": 473
  }, {
    "name": "五家渠",
    "id": 474
  }, {
    "name": "伊犁哈萨克自治州",
    "id": 475
  }, {
    "name": "其他",
    "id": 476
  }]
}, {
  "name": "台湾",
  "id": 32,
  "child": [{
    "name": "台北市",
    "id": 477
  }, {
    "name": "高雄市",
    "id": 478
  }, {
    "name": "台北县",
    "id": 479
  }, {
    "name": "桃园县",
    "id": 480
  }, {
    "name": "新竹县",
    "id": 481
  }, {
    "name": "苗栗县",
    "id": 482
  }, {
    "name": "台中县",
    "id": 483
  }, {
    "name": "彰化县",
    "id": 484
  }, {
    "name": "南投县",
    "id": 485
  }, {
    "name": "云林县",
    "id": 486
  }, {
    "name": "嘉义县",
    "id": 487
  }, {
    "name": "台南县",
    "id": 488
  }, {
    "name": "高雄县",
    "id": 489
  }, {
    "name": "屏东县",
    "id": 490
  }, {
    "name": "宜兰县",
    "id": 491
  }, {
    "name": "花莲县",
    "id": 492
  }, {
    "name": "台东县",
    "id": 493
  }, {
    "name": "澎湖县",
    "id": 494
  }, {
    "name": "基隆市",
    "id": 495
  }, {
    "name": "新竹市",
    "id": 496
  }, {
    "name": "台中市",
    "id": 497
  }, {
    "name": "嘉义市",
    "id": 498
  }, {
    "name": "台南市",
    "id": 499
  }, {
    "name": "其他",
    "id": 500
  }]
}, {
  "name": "澳门",
  "id": 33,
  "child": [{
    "name": "花地玛堂区",
    "id": 501
  }, {
    "name": "圣安多尼堂区",
    "id": 502
  }, {
    "name": "大堂区",
    "id": 503
  }, {
    "name": "望德堂区",
    "id": 504
  }, {
    "name": "风顺堂区",
    "id": 505
  }, {
    "name": "嘉模堂区",
    "id": 506
  }, {
    "name": "圣方济各堂区",
    "id": 507
  }, {
    "name": "路凼",
    "id": 508
  }, {
    "name": "其他",
    "id": 509
  }]
}, {
  "name": "香港",
  "id": 34,
  "child": [{
    "name": "中西区",
    "id": 510
  }, {
    "name": "湾仔区",
    "id": 511
  }, {
    "name": "东区",
    "id": 512
  }, {
    "name": "南区",
    "id": 513
  }, {
    "name": "深水埗区",
    "id": 514
  }, {
    "name": "油尖旺区",
    "id": 515
  }, {
    "name": "九龙城区",
    "id": 516
  }, {
    "name": "黄大仙区",
    "id": 517
  }, {
    "name": "观塘区",
    "id": 518
  }, {
    "name": "北区",
    "id": 519
  }, {
    "name": "大埔区",
    "id": 520
  }, {
    "name": "沙田区",
    "id": 521
  }, {
    "name": "西贡区",
    "id": 522
  }, {
    "name": "元朗区",
    "id": 523
  }, {
    "name": "屯门区",
    "id": 524
  }, {
    "name": "荃湾区",
    "id": 525
  }, {
    "name": "葵青区",
    "id": 526
  }, {
    "name": "离岛区",
    "id": 527
  }, {
    "name": "其他",
    "id": 528
  }]
}]
