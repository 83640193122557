import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en' //导入element ui中的默认英文配置
import chLocale from 'element-ui/lib/locale/lang/zh-CN' //导入element ui中的默认中文配置
import en from './en'
import cn from './cn'
Vue.use(VueI18n);
const messages = {
  en: {
    message: 'hello',
      ...enLocale,
      ...en //把导入的英文词条合并
  },
  cn: {
    message: '你好',
      ...chLocale,
      ...cn //把导入的中文词条合并
  }
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'cn', // 通过this.$i18n.locale的值实现语言切换
  messages,
});

export default i18n;
