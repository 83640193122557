import Vue from "vue";
import Vuex from "vuex";
import {
  getUserInfo,
  getUserInfoCopy
} from "@/api/user.js";
import {
  url
} from "@/main";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    AVATARSRC: require("@/assets/imgs/woman.png"),
    userInfo: {
      phonenumber: "",
    },
    serverTime: "",
    userScore: {},
    accountNumber: [],
  },
  mutations: {
    SET_AVATAR(state, AVATARSRC) {
      state.AVATARSRC = AVATARSRC;
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_SCOREANDSUBJECT(state, obj) {
      state.userScore = obj.score;
      state.userSubject = obj.subject;
    },
    SET_SERVERTIME(state, serverTime) {
      state.serverTime = new Date(serverTime);
    },
    SET_USERSCORE(state, obj) {
      state.userScore = obj;
    },
    SET_ACCOUNTNUMBER(state, accountNumber) {
      state.accountNumber = accountNumber;
    },
    RESET_USERINFO(state) {
      state.userInfo = {
        phonenumber: "",
      }
    }
  },
  actions: {
    // 储存用户头像【弃用】
    setAvatar({
      commit
    }, AVATARSRC) {
      commit("SET_AVATAR", AVATARSRC);
    },
    // 储存用户信息
    setUserinfo({
      commit,
      dispatch
    }) {
      // 调接口，拿用户信息。
      let phonenumber = localStorage.getItem("phonenumber");
      if (phonenumber) {
        return new Promise((resolve, reject) => {
          getUserInfo({phonenumber: phonenumber,userId: "",}).then((res) => {
              if (res.code == 200) {
                commit("SET_USERINFO", res.data);
                resolve(res.data);
                // getUserInfoCopy({
                //   phonenumber: phonenumber,
                // }).then((response) => {
                //   res.data.remark = response.data.remark;
                //   commit("SET_USERINFO", res.data);
                //   resolve(res.data);
                // });
              }
            })
            .catch((err) => {
              // localStorage.removeItem("token");
              // localStorage.removeItem("phonenumber");
              // router.push("/");
            });
        });
      }
    },
    // 存储服务器的时间
    setServerTime({
      commit,
      dispatch
    }, serverTime) {
      commit("SET_SERVERTIME", serverTime);
    },
    // 存储字典手机号，特殊账号
    setAccountNumber({
      commit,
      dispatch
    }, accountNumber) {
      commit("SET_ACCOUNTNUMBER", accountNumber);
    },
    // 退出登录，清空用户信息
    resetUserInfo({
      commit,
      dispatch
    }) {
      commit("RESET_USERINFO");
    },
  },
  modules: {},
});
