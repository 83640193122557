import axios from 'axios'
import qs from 'qs'
import router from '@/router'
import store from '@/store'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import { rsaDecode, aesDecode, aesEncode, rsaEncode, generateKey } from '@/utils/magicencrypt'
import { tansParams, blobValidate } from '@/utils/ruoyi'
import urls from '@/utils/encryptapis'
let downloadLoadingInstance
// 是否显示重新登录
let isReloginShow

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'http://192.168.0.152:9090',
  // baseURL: 'http://8.130.65.145:8089',
  // 请求的超时时间
  timeout: 600000,
  // form、json
  reqDataType: 'json',
  // 是否需要token
  needtoken: true,

})
// 请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (config.reqDataType === 'form') {
    config.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    var newFormData = new FormData()
    var person = config.data
    for (const key in person) {
      if (Object.prototype.hasOwnProperty.call(person, key)) {
        newFormData.append(key, person[key])
      }
    }
    config.data = newFormData
  } else if (config.reqDataType === 'json') {
    config.headers = {
      'Content-Type': 'application/json;charset=utf-8' //对应后端的 @RequestBody
      // 'Content-Type': 'application/x-www-form-urlencoded' //对应后端的 @RequestParam
    }

    // 如果post传参，后端接收参数是'@RequestParam'，那么就将contentType修改成'application/x-www-form-urlencoded'，并且将参数进行序列化（config.data = qs.stringify(config.data)）。
  } else if (config.reqDataType === 'file') {
    config.headers = { 'Content-Type': 'multipart/form-data' }
  }

  // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token
  // 在响应拦截器返回中判断本地token是否过期
  const token = localStorage.getItem('token')
  if (config.needtoken == true) {
    token && (config.headers.Authorization = 'Bearer ' + token)
  }
  if (!token) {
    config.headers.notLogin = 'usernotlogin_fsdfsjfkjjlkgjjfss'
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
    return config
  }
  if (urls.urls.includes(config.url)) {
    const key = generateKey()
    let data = JSON.stringify(config.data)
    data = aesEncode(data, key)
    config.data = {}
    config.data = data
    const encodeAesKey = rsaEncode(key)
    config.headers['x-magic-front-header'] = encodeAesKey
  }else{
    if (config.isNeedString) { //把数据转成字符串，后端就不用建立VO了
      config.data = JSON.stringify(config.data)
    }
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function (response) {

  if (response.data['date']) {
    store.dispatch('setServerTime', new Date(response.data['date']))
  } else {
    var ServerTime = new Date()
    store.dispatch('setServerTime', ServerTime)
  }
  //  if (response.headers['date']) {
  //   var dateee = new Date(response.headers['date']).toJSON();
  //   var ServerTime =  new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  //   store.dispatch('setServerTime', new Date(ServerTime))
  //  }else{
  //   var ServerTime =  new Date()
  //   store.dispatch('setServerTime', ServerTime)
  //  }
  // 对响应数据做点什么
  // 二进制数据则直接返回
  if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
    return response.data
  }
  if (response.data.code == 200) {
    const data = response.data
    let key = response.headers['x-magic-header']
    if (key && key != null) {
      key = rsaDecode(key)
      data.data = JSON.parse(aesDecode(data.data, key))
    }
    return data
  } else {
    if (response.data.code) {
      switch (response.data.code) {
        // 201
        case 201:
          return response.data
          break
        // 401: 未登录
        case 401:

          break
        // 403 token过期
        case 403:

          break
        // 404请求不存在
        case 404:
          Message({
            message: '网络请求不存在',
            type: 'error'
          })
          break
        // 其他错误，直接抛出错误提示
        default:

          Message({
            message: response.data.msg,
            type: 'error'
          })
          return Promise.reject(response.data)
          const magArr = ['已在其他设备登录，本地已退出，请重新登录！', 'token不正确，请不要通过非法手段创建token', '用户不存在，请重新登录!', '登录已过期，请重新登录!', '无token，请重新登录']

          if (magArr.includes(response.data.msg)) {
            localStorage.removeItem("token");
            localStorage.removeItem("phonenumber");
            router.push('/'); //跳转到首页
            router.go(0)      //强制刷新页面，清除keep-alive的缓存
          }
      }
    }

  }
  return Promise.reject(response.data)
}, function (error) {
  // 对响应错误做点什么
  console.log('err' + error)
  let { message } = error
  if (message == 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  Message({
    message: message,
    type: 'error'
  })
})
var map = {
  零: 0,

  一: 1,
  壹: 1,

  二: 2,
  贰: 2,
  两: 2,

  三: 3,
  叁: 3,

  四: 4,
  肆: 4,

  五: 5,
  伍: 5,

  六: 6,
  陆: 6,

  七: 7,
  柒: 7,

  八: 8,
  捌: 8,

  九: 9,
  玖: 9,

  十: 10,
  拾: 10,

  百: 100,
  佰: 100,

  千: 1000,
  仟: 1000,

  万: 10000,
  十万: 100000,
  百万: 1000000,
  千万: 10000000,
  亿: 100000000
}

// 解析失败返回-1，成功返回转换后的数字，不支持负数   【将大写数字转小写(阿拉伯数字)】 九十六==>96
export function numberDigit(chinese_number) {
  var len = chinese_number.length
  if (len == 0) return -1
  if (len == 1) return (map[chinese_number] <= 10) ? map[chinese_number] : -1
  var summary = 0
  if (map[chinese_number[0]] == 10) {
    chinese_number = '一' + chinese_number
    len++
  }
  if (len >= 3 && map[chinese_number[len - 1]] < 10) {
    var last_second_num = map[chinese_number[len - 2]]
    if (last_second_num == 100 || last_second_num == 1000 || last_second_num == 10000 || last_second_num == 100000000) {
      for (var key in map) {
        if (map[key] == last_second_num / 10) {
          chinese_number += key
          len += key.length
          break
        }
      }
    }
  }
  if (chinese_number.match(/亿/g) && chinese_number.match(/亿/g).length > 1) return -1
  var splited = chinese_number.split('亿')
  if (splited.length == 2) {
    var rest = splited[1] == '' ? 0 : numberDigit(splited[1])
    return summary + numberDigit(splited[0]) * 100000000 + rest
  }
  splited = chinese_number.split('万')
  if (splited.length == 2) {
    var rest = splited[1] == '' ? 0 : numberDigit(splited[1])
    return summary + numberDigit(splited[0]) * 10000 + rest
  }
  var i = 0
  while (i < len) {
    var first_char_num = map[chinese_number[i]]
    var second_char_num = map[chinese_number[i + 1]]
    if (second_char_num > 9) { summary += first_char_num * second_char_num }
    i++
    if (i == len) { summary += first_char_num <= 9 ? first_char_num : 0 }
  }
  return summary
}

// 导出axios实例
export default request
