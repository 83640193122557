export default {
  message: {
    'hello': 'hello world',
    'desc': 'Ant Design is the most influential web design specification in Xihu district',
    'loginAccount': 'Credentials',
    'loginByPhone': 'Mobile number',
    'account': 'Account',
    'password': 'password',
    'autoLofin': 'Remember me',
    'forgetPassword': 'Forgot your password?',
    'loginBtn': 'Login',
    'otherLoginWay': 'Sign in with',
    'register': 'Sign up',
    'help': 'help',
    'privacy': 'privacy',
    'clause': 'clause',
    'phone': 'Mobile Number',
    'code': 'Verification code',
    'getCode': 'Get code',
    'content': 'please input content',
    'successInfo':'change success',
    'date':'date',
    'name':'name',
    'title': 'title',
    'address':'address',
  }
}
