import request from '@/utils/request.js'
// 获取短信验证码 /dev/api/sendSmsCode/{phoneNumber}
export function REGGETphonecode(data) {
  return request({
    method: 'post',
    url: '/sendSmsCode',
    data: data
  })
}
// 获取微信用户完善信息获取验证码 /dev/api/sendSmsCode/{phoneNumber}
export function REGGETphonecodeCopy(data) {
  return request({
    method: 'post',
    url: '/sendWxBindSmsCode',
    data: data
  })
}
// 微信登录注册信息换绑手机号
export function bindWxPhonenumber(data) {
  return request({
    method: 'post',
    url: '/system/userDetails/bindWxPhonenumber',
    data: data
  })
}
// 短信验证码登录   /dev/api/smsLogin
export function REGsmsLogin(data) {
  return request({
    method: 'post',
    url: '/smsLogin',
    data: data,
    reqDataType: 'json'
  })
}
// 获取图文验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get'

  })
}
// 手机密码登录【和若依一模一样】
export function login(data) {
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data,
    reqDataType: 'json'
  })
}
// 编辑用户详细信息【仅在登录时使用】 /dev/api
export function REGUserEdit(data) {
  return request({
    method: 'post',
    url: '/system/user/updateDetails',
    data: data,
    reqDataType: 'json'
  })
}

// 编辑用户详细信息
export function REGUserEditNoPass(data) {
  // console.log(data)
  return request({
    method: 'post',
    url: '/system/userDetails/edit',
    data: data,
    reqDataType: 'json'
  })
}
// 根据手机号或者userid查询用户信息 [查询user_details这个表]
export function getUserInfo(data) {
  if (!data.phonenumber) {
    return
  }
  return request({
    method: 'get',
    url: '/system/userDetails/queryByUserDetails',
    params: data
  })
}
// 根据手机号查询用户   /dev/api/system/user/queryByUser
export function getUserInfoCopy(data) {
  return request({
    method: 'get',
    url: '/system/user/queryByUser',
    params: data
  })
}
// 重置密码   /dev/api
export function resetPwdUser(data) {
  return request({
    method: 'post',
    url: '/system/user/resetPwdUser',
    data: data,
    reqDataType: 'json'

  })
}
// 退出登录
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}
// 用户扫码登录
export function wxlogin(data) {
  return request({
    url: '/system/wxlogin/callback',
    method: 'get',
    params: data
  })
}
// 获取邀请人
export function getInvitation(data) {
  return request({
    url: '/system/userDetails/queryByUserVerificationCode',
    method: 'post',
    data
  })
}
// 获取邀请二维码
export function getWxQR(data) {
  return request({
    url: '/system/wxlogin/getwxacodeunlimit',
    method: 'post',
    data: data
  })
}
// 获取邀请人上下级关系{code:要查询的码， userCode：自己的码，upAndDown：‘up'或者'dowmn'查看上级或下级}
export function getInvitationUpOrDown(data) {
  return request({
    url: '/system/userDetails/queryInviterUpNextLevelCode',
    method: 'post',
    data
  })
}


export function queryDetail(data) {
  return request({
    url: '/system/userscoreplanstable/queryDetail',
    method: 'get',
    params: data
  })
}

export function removeDetail(data) {
  return request({
    url: '/system/userscoreplanstable/removeDetail',
    method: 'get',
    params: data
  })
}

export function queryDetailList(data) {
  return request({
    url: '/system/userscoreplanstable/queryDetailList',
    method: 'get',
    params: data
  })
}


// 历史记录
export function getUpdateCoreNo(data) {

  return request({
    url: `/system/userDetails/getUpdateCoreNo`,
    method: 'get',
    params: data

  })
}

// 激活记录

export function getrecord(data) {
  return request({
    url: `/system/activationCode/getrecord?userPhone=${data.userPhone}`,
    method: 'post',
    data
  })
}


